//use this as a callback function to setstates
/*const finalfilesset = (finaltempfiles) => {
  setTempfiles((prev) => [...prev, ...finaltempfiles]);
};*/

//alternatively make a callback function to set the states

function FourFilesUploader({
  tempfiles,
  /* setTempfiles,*/
  finalfilesset,
  setError,
  twominutevideo,
  setTwominutevideo,
}) {
  const fileedit = async (e) => {
    for (let step = 0; step < e.target.files.length; step++) {
      if (
        !e.target.files[step]?.type.includes("video") &&
        !e.target.files[step]?.type.includes("image")
      ) {
        console.log(e.target.files[step]);
        // alert("ファイルは動画か写真にしましょう");
        setError("ファイルは動画か写真にしましょう");
        ///////////////////////////////////////////
        return (e.target.value = "");
      }
    }
    console.log("This better not run");
    //.type.includes("video")
    if (tempfiles.length + e.target.files.length < 5) {
      let filenumber = 0;
      let finaltempfiles = [];
      let promises = [];

      for (let step = 0; step < e.target.files.length; step++) {
        promises.push(
          await new Promise((resolve, reject) => {
            let fileurl = window.URL.createObjectURL(
              e.target.files[filenumber]
            );
            console.log(fileurl);
            finaltempfiles.push({
              file: fileurl,
              id: e.target.files[filenumber].name + new Date().getTime(),
              realfile: e.target.files[filenumber],
            });

            if (e.target.files[filenumber].type.includes("video")) {
              let videotime = document.createElement("video");
              videotime.muted = true;

              console.log(videotime);

              let videotimesource = document.createElement("source");
              videotimesource.src = fileurl;
              videotime.preload = "metadata";
              videotime.appendChild(videotimesource);

              videotime.onloadedmetadata = () => {
                console.log(Math.floor(videotime.duration));
                console.log(twominutevideo);
                //videotime needds to be passed in from parent
                //tempfiles length needs to be passed in?
                if (
                  videotime.duration > 120 ||
                  twominutevideo + Math.floor(videotime.duration) > 120
                ) {
                  URL.revokeObjectURL(fileurl);
                  setError(
                    "動画は２分以内でなければいけません、２分以上の動画はE-Stationに投稿しましょう"
                  );
                  //alert(
                  //  "動画は２分以内でなければいけません、２分以上の動画はE-Stationに投稿しましょう"
                  // );
                  e.target.value = "";
                  reject("over");
                } else {
                  console.log(finaltempfiles);
                  filenumber++;
                  setTwominutevideo(
                    (prevvalue) => prevvalue + Math.floor(videotime.duration)
                  );

                  resolve();
                }
              };
            } else {
              console.log(finaltempfiles);
              filenumber++;
              resolve();
            }
          })
        );
      }
      try {
        await promises[0];
        await promises[1];
        await promises[2];
        await promises[3];

        // setTempfiles([...tempfiles, ...finaltempfiles]);
        //if you want this to be a callback function to set the states
        finalfilesset(finaltempfiles);

        setTimeout(() => {
          e.target.value = "";
          /* if (tempfiles.length >= 1) {
          setShowtime(true);
        }*/
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      e.target.value = "";
      alert("ファイルは４つまでです");
      setError("ファイルは４つまでです");
    }
  };

  return (
    <label>
      <img className="camera" src="./Photo.svg" />
      <input
        className="upload"
        type="file"
        multiple="multiple"
        onChange={fileedit}
      />
    </label>
  );
}
export default FourFilesUploader;
