import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { storage, auth, db } from "./Firebase";
import { signOut, onAuthStateChanged } from "firebase/auth";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
  deleteObject,
  uploadString,
} from "firebase/storage";
import {
  doc,
  updateDoc,
  getDoc,
  getDocs,
  collection,
  runTransaction,
  deleteDoc,
  increment,
  writeBatch,
  collectionGroup,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { Link, useParams, useNavigate } from "react-router-dom";
import LowControl from "./LowControl";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import FullScreenImage from "./Customhooks/FullScreenImage.js";
import FullScreenVideo from "./Customhooks/FullScreenVideo.js";
import { Fullscreenstyles } from "./Styling/styles.js";

function User() {
  const { userid } = useParams();
  const ref1 = useRef(false);
  const ref2 = useRef(false);
  const currenturl = "User";
  const droppedvideoref = useRef([]);

  const [link, setLink] = useState("");
  const [id, setId] = useState("");
  const [posts, setPosts] = useState([]);
  const [dropview, setDropView] = useState(false);
  const [tempintro, setTempintro] = useState("");
  const [unredrop, setUnredrop] = useState("");
  const [unredropd, setUnredropd] = useState("");
  const [unredropdid, setUnredropdid] = useState("");
  const [unredropid1, setUnredropid1] = useState("");
  const [redropswitch, setRedropswitch] = useState(true);
  const [option, setOption] = useState(false);
  const [tempintro1, setTempintro1] = useState("");
  const [pressnumber, setPressnumber] = useState([]);
  const [pressnumber1, setPressnumber1] = useState([]);
  const [pressnumber2, setPressnumber2] = useState([]);
  const [backspaceslide, setBackspaceslide] = useState(false);
  const [newline, setNewline] = useState(false);
  const [image, setImage] = useState("");
  const [cropdata, setCropdata] = useState("");
  const [cropper, setCropper] = useState(null);
  const [enterlimit, setEnterlimit] = useState([]);
  const [photourl, setPhotourl] = useState("");
  const [userinfo, setUserinfo] = useState("");
  const [followercount, setFollowercount] = useState("");
  const [pledgegreen, setPledgegreen] = useState(false);
  const [fullscreened, setFullscreened] = useState("");
  const [headfiles, setHeadfiles] = useState("");
  const [rankupanimation, setRankupanimation] = useState(false);
  const [fullscreentarget, setFullscreentarget] = useState([]);
  //const [turnoffrankupanimation, setTurnoffrankupanimation] = useState(false);

  const navigate = useNavigate();

  let Fullscreenstyleson = fullscreentarget.length ? Fullscreenstyles : {};

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate(`/`);
      }
      if (user) {
        console.log(userid);
        console.log(user.uid);
        setId(user.uid);

        console.log(userid === user.uid);
        const userDocumentRef = doc(db, "users", user.uid);
        await getDoc(userDocumentRef).then((documentSnapshot) => {
          let selfdata = documentSnapshot.data();
          setUserinfo({ ...selfdata, id: user.uid });
          setTempintro1(selfdata.Intro);

          const dropsRef = query(
            collectionGroup(db, "Following"),
            where("Follow_id", "==", user.uid)
          );

          getDocs(dropsRef).then((querySnapshot) => {
            setFollowercount(querySnapshot.docs.length);
          });

          const userDocumentRef = collection(
            db,
            "users",
            user.uid,
            "PledgePlan"
          );
          getDocs(userDocumentRef).then((documentSnapshot) => {
            if (documentSnapshot.size > 0) {
              console.log(documentSnapshot.size);
              setPledgegreen(true);
            }
          });

          //////
          if (selfdata.Rank !== selfdata.PreviousRank) {
            // updateDoc(
            //doc(db, "users", user.uid),
            // {
            // PreviousRank: selfdata.Rank,
            //  }
            //);
            if (selfdata.Rank < selfdata.PreviousRank) {
              setRankupanimation(true);
              setTimeout(() => {
                setRankupanimation(false);
              }, 5000);
              //do we really not need useeffect here?
              // setTimeout(()=>{setTurnoffrankupanimation(true)},2000)
            }
          }

          const userCollectionRef = collection(db, "users", user.uid, "Drops");
          getDocs(
            query(
              userCollectionRef,

              orderBy("Timestamp", "desc")
            )
          ).then((querySnapshot) => {
            let posts = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
            console.log(posts);
            /////////////////////////////////
            //this is where you need to adjust the redropped dates
            posts.map((post) => {
              if (!post.Redropper_id) {
                post.year = post.Timestamp.toDate().getFullYear();
                post.month = (
                  "0" +
                  (post.Timestamp.toDate().getMonth() + 1)
                ).slice(-2);
                post.date = ("0" + post.Timestamp.toDate().getDate()).slice(-2);
                post.hour = post.Timestamp.toDate().getHours();
                post.minutes = (
                  "0" + post.Timestamp.toDate().getMinutes()
                ).slice(-2);
              }

              /*else{
 post.year = post.Timestamp.toDate().getFullYear();
 post.month = ("0" + (post.Timestamp.toDate().getMonth() + 1)).slice(-2);
 post.date = ("0" + post.Timestamp.toDate().getDate()).slice(-2);
 post.hour = post.Timestamp.toDate().getHours();
 post.minutes = ("0" + post.Timestamp.toDate().getMinutes()).slice(-2);


            }*/
            });
            console.log(posts);
            setPosts(posts);
          });

          const userCollectionRef1 = collection(
            db,
            "users",
            user.uid,
            "PledgeHold"
          );
          getDocs(userCollectionRef1).then((querySnapshot) => {
            let posts1 = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));

            if (!ref1.current) {
              ref1.current = true;

              let new_posts1 = [];
              let allrank = [];
              let rank_posts2 = [];
              let pledgeprice = "";
              let rankprice = 0;

              const round = (price) => {
                return price - (price * selfdata.Deduction) / 100;
              };
              const bonus = (price) => {
                return (price * ((selfdata.Deduction - 20) / 2)) / 100;
              };

              const userCollectionRef1 = collection(
                db,
                "users",
                user.uid,
                "OutstandingPay"
              );

              getDocs(userCollectionRef1).then((querySnapshot) => {
                rank_posts2 = querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }));
                console.log(rank_posts2);
                if (rank_posts2.length > 0) {
                  rank_posts2 = rank_posts2.filter(
                    (post) => post.For === "Rank"
                  );

                  rank_posts2.forEach((post) => {
                    (async () => {
                      console.log(post.Stripe_id);
                      try {
                        const response = await axios.get(
                          `http://localhost:4000/v1/payment_intents/${post.Stripe_id}`,
                          {
                            params: {
                              data: post.Stripe_id,
                            },
                          }
                        );

                        if (response.data.success) {
                          console.log(response.data);

                          if (
                            response.data.status === "succeeded" ||
                            response.data.status === "requires_payment_method"
                          ) {
                            const userDocumentRef = doc(
                              db,
                              "users",
                              userid,
                              "OutstandingPay",
                              post.id
                            );
                            await deleteDoc(userDocumentRef);
                          }

                          if (response.data.status === "succeeded") {
                            rankprice = rankprice + post.Price;
                          }
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    })();
                  });

                  allrank = [selfdata.Rank];
                }
              });

              if (posts1.length > 0) {
                new_posts1 = posts1.filter(
                  (post) =>
                    new Date().getTime() > post.ExpireNumber + 86400000 * 3 &&
                    !post.investigation
                );
                setTimeout(() => {
                  console.log(new_posts1);

                  console.log(new Date().getTime() - 86400000 * 3.1);

                  if (new_posts1.length > 0) {
                    allrank = [selfdata.Rank];
                    let init = 0;
                    pledgeprice = new_posts1.reduce((a, b) => ({
                      Price: a.Price + b.Price,
                    }));

                    console.log(pledgeprice.Price);

                    console.log(pledgeprice);

                    new_posts1.forEach((post) => {
                      updateDoc(doc(db, "users", post.PaidUser), {
                        Total_Currency_Generated: increment(
                          Math.round(
                            round(post.Price) + Math.round(bonus(post.Price))
                          )
                        ),
                      });

                      const userDeleteRef = doc(
                        db,
                        "users",
                        user.uid,
                        "PledgeHold",
                        post.id
                      );
                      deleteDoc(userDeleteRef);

                      updateDoc(
                        doc(db, "users", user.uid, "PledgePlan", post.Pledgeid),
                        {
                          subscribers: increment(-1),
                        }
                      );

                      const userDocumentRef2 = doc(db, "users", post.PaidUser);
                      getDoc(userDocumentRef2).then((documentSnapshot) => {
                        allrank.push(selfdata.Rank);
                      });
                    });
                  }
                }, 100);
              }

              if (new_posts1.length > 0 || rank_posts2.length > 0) {
                setTimeout(() => {
                  let cardprice = Math.round(round(pledgeprice.Price));

                  updateDoc(doc(db, "users", user.uid), {
                    Current_Currency: increment(
                      (cardprice ? cardprice : 0) +
                        (rank_posts2.length > 0 && rankprice)
                    ),
                    Total_Currency_Generated: increment(
                      (cardprice ? cardprice : 0) +
                        (cardprice ? Math.round(bonus(pledgeprice.Price)) : 0) +
                        (rank_posts2.length > 0 && rankprice)
                    ),
                  });
                }, 100);

                setTimeout(() => {
                  let lowestrank = Math.max.apply(null, allrank);
                  console.log(lowestrank);
                  if (
                    (rank_posts2.length >= 0 &&
                      new_posts1.length > 0 &&
                      lowestrank > 2) ||
                    (rank_posts2.length > 0 &&
                      new_posts1.length === 0 &&
                      lowestrank > 1)
                  ) {
                    const rankCollectionRef = collection(db, "users");
                    getDocs(
                      query(rankCollectionRef, where("Rank", "<=", lowestrank))
                    ).then((querySnapshot) => {
                      let posts2 = querySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                      }));
                      console.log(posts2);

                      let cleanallrank = [...new Set(allrank)];
                      console.log(cleanallrank);

                      let num = 0;
                      let newrank = [];
                      for (let step = 0; step < cleanallrank.length; step++) {
                        newrank.push(
                          posts2.findIndex(
                            (element) =>
                              posts2[cleanallrank[num] - 1]
                                .Total_Currency_Generated >
                              element.Total_Currency_Generated
                          )
                        );
                        num++;
                      }

                      console.log(newrank);

                      const notrun = (currentValue) => currentValue === -1;
                      console.log(newrank.every(notrun) === true);
                      if (newrank.every(notrun) === false) {
                        posts2.sort(
                          (a, b) =>
                            b.Total_Currency_Generated -
                            a.Total_Currency_Generated
                        );

                        console.log(posts2);

                        let highranks = newrank.filter(
                          (highrank) => highrank !== -1
                        );

                        console.log(highranks);

                        if (highranks.length > 0) {
                          let highestrank = Math.min(...highranks);
                          console.log(highestrank);

                          let posts3 =
                            highestrank === 0
                              ? posts2
                              : posts2.slice(highestrank);

                          console.log(posts3);

                          let Rankupdate = [];
                          posts3.forEach((post) => {
                            const RankRef = collection(
                              db,
                              "users",
                              post.id,
                              "Drops"
                            );
                            getDocs(RankRef).then((querySnapshot) => {
                              let Rankposts = querySnapshot.docs.map((doc) => ({
                                ...doc.data(),
                                id: doc.id,
                              }));
                              console.log(Rankposts);
                              let Rankposts2 = Rankposts.filter(
                                (element) =>
                                  (!element.Sender_id &&
                                    element.Directory === post.id) ||
                                  element.Sender_id === post.id
                              );
                              Rankupdate.push(Rankposts2);
                            });
                          });

                          let Rankupdate1 = [];
                          posts3.forEach((post) => {
                            const RankRef = collection(
                              db,
                              "users",
                              post.id,
                              "Comments"
                            );
                            getDocs(RankRef).then((querySnapshot) => {
                              let Rankposts1 = querySnapshot.docs.map(
                                (doc) => ({
                                  ...doc.data(),
                                  id: doc.id,
                                })
                              );
                              console.log(Rankposts1);
                              Rankupdate1.push(Rankposts1);
                              console.log(Rankupdate1);
                            });
                          });

                          let Rankupdate2 = [];
                          posts3.forEach((post) => {
                            const RankRef = query(
                              collectionGroup(db, "Message"),
                              where("from", "==", post.id) ||
                                where("to", "==", post.id)
                            );
                            getDocs(RankRef).then((querySnapshot) => {
                              let Rankposts2 = querySnapshot.docs.map(
                                (doc) => ({
                                  ...doc.data(),
                                  id: doc.id,
                                })
                              );

                              Rankposts2 = Rankposts2.filter(
                                (element) => element.id === post.id
                              );

                              setTimeout(() => {
                                console.log(Rankposts2);
                                Rankupdate2.push(Rankposts2);
                                console.log(Rankupdate2);
                              }, 100);
                            });
                          });

                          let Rankupdate3 = [];
                          posts3.forEach((post) => {
                            const RankRef = query(
                              collectionGroup(db, "Drops"),
                              where("Redropper_id", "!=", false),
                              where("Sender_id", "==", post.id)
                            );
                            getDocs(RankRef).then((querySnapshot) => {
                              let Rankposts3 = querySnapshot.docs.map(
                                (doc) => ({
                                  ...doc.data(),
                                  id: doc.id,
                                })
                              );

                              const RankRef1 = query(
                                collectionGroup(db, "Drops"),
                                where("Redropper_id", "!=", false),
                                where("Directory", "==", post.id)
                              );
                              getDocs(RankRef1).then((querySnapshot) => {
                                let Rankposts4 = querySnapshot.docs.map(
                                  (doc) => ({
                                    ...doc.data(),
                                    id: doc.id,
                                  })
                                );

                                let FilteredRankposts = Rankposts4.filter(
                                  (post) => !post.Sender_id
                                );

                                console.log(Rankposts3);
                                console.log(FilteredRankposts);
                                let ReRankposts = [
                                  ...Rankposts3,
                                  ...FilteredRankposts,
                                ];
                                console.log(ReRankposts);
                                setTimeout(() => {
                                  Rankupdate3.push(ReRankposts);
                                  console.log(Rankupdate3);
                                }, 100);
                              });
                            });
                          });

                          let Rankupdate4 = [];
                          posts3.forEach((post) => {
                            const RankRef = query(
                              collectionGroup(db, "Notification"),
                              where("Sender_id", "==", post.id)
                            );
                            getDocs(RankRef).then((querySnapshot) => {
                              let Rankposts5 = querySnapshot.docs.map(
                                (doc) => ({
                                  ...doc.data(),
                                  id: doc.id,
                                })
                              );

                              console.log(Rankposts5);

                              const RankRef1 = query(
                                collectionGroup(db, "Notification"),
                                where("Type", "==", "Follow"),
                                where("Directory", "==", post.id)
                              );
                              getDocs(RankRef1).then((querySnapshot) => {
                                let Rankposts6 = querySnapshot.docs.map(
                                  (doc) => ({
                                    ...doc.data(),
                                    id: doc.id,
                                  })
                                );

                                console.log(Rankposts6);

                                const RankRef2 = query(
                                  collectionGroup(db, "Notification"),
                                  where("Redropper_id", "==", post.id)
                                );
                                getDocs(RankRef2).then((querySnapshot) => {
                                  let Rankposts7 = querySnapshot.docs.map(
                                    (doc) => ({
                                      ...doc.data(),
                                      id: doc.id,
                                    })
                                  );

                                  console.log(Rankposts7);
                                  setTimeout(() => {
                                    Rankupdate4.push([
                                      ...Rankposts5,
                                      ...Rankposts6,
                                      ...Rankposts7,
                                    ]);
                                    console.log(Rankupdate4);
                                  }, 100);
                                });
                              });
                            });
                          });

                          let Rankupdate5 = [];
                          posts3.forEach((post) => {
                            const RankRef = query(
                              collectionGroup(db, "Following"),
                              where("Directory", "==", post.id)
                            );
                            getDocs(RankRef).then((querySnapshot) => {
                              let Rankposts6 = querySnapshot.docs.map(
                                (doc) => ({
                                  ...doc.data(),
                                  id: doc.id,
                                })
                              );

                              console.log(Rankposts6);
                              Rankupdate5.push(Rankposts6);
                            });
                          });

                          let batchArray = [];
                          batchArray.push(writeBatch(db));
                          let batchCount = 0;
                          let batchIndex = 0;
                          let number = 1 + posts2.length - posts3.length;
                          posts3.forEach((post) => {
                            const userDocumentRef = doc(db, "users", post.id);
                            batchArray[batchIndex].update(userDocumentRef, {
                              Rank: number,
                            });
                            batchCount++;
                            number++;

                            if (batchCount === 499) {
                              batchArray.push(writeBatch(db));
                              batchIndex++;
                              batchCount = 0;
                            }
                          });

                          batchArray.forEach(
                            async (batch) => await batch.commit()
                          );

                          setTimeout(() => {
                            console.log(Rankupdate);

                            let batchArray1 = [];
                            batchArray1.push(writeBatch(db));
                            let batchCount1 = 0;
                            let batchIndex1 = 0;
                            let number1 = 0 + posts2.length - posts3.length;
                            Rankupdate.forEach((post) => {
                              number1++;
                              console.log(number1);
                              post.forEach((element) => {
                                if (post.length > 0) {
                                  const userDocumentRef = doc(
                                    db,
                                    "users",
                                    element.Sender_id
                                      ? element.Sender_id
                                      : element.Directory,
                                    "Drops",
                                    element.id
                                  );
                                  batchArray1[batchIndex1].update(
                                    userDocumentRef,
                                    {
                                      Rank: number1,
                                    }
                                  );
                                  batchCount1++;

                                  if (batchCount1 === 499) {
                                    batchArray1.push(writeBatch(db));
                                    batchIndex1++;
                                    batchCount1 = 0;
                                  }
                                }
                              });
                            });

                            batchArray1.forEach(
                              async (batch) => await batch.commit()
                            );

                            let batchArray2 = [];
                            batchArray2.push(writeBatch(db));
                            let batchCount2 = 0;
                            let batchIndex2 = 0;
                            let number2 = 0 + posts2.length - posts3.length;
                            Rankupdate1.forEach((post) => {
                              number2++;
                              console.log(number2);
                              post.forEach((element) => {
                                if (post.length > 0) {
                                  const userDocumentRef = doc(
                                    db,
                                    "users",
                                    element.Sender_id,
                                    "Comments",
                                    element.id
                                  );
                                  batchArray2[batchIndex2].update(
                                    userDocumentRef,
                                    {
                                      Rank: number2,
                                    }
                                  );
                                  batchCount2++;

                                  if (batchCount2 === 499) {
                                    batchArray2.push(writeBatch(db));
                                    batchIndex2++;
                                    batchCount2 = 0;
                                  }
                                }
                              });
                            });

                            batchArray2.forEach(
                              async (batch) => await batch.commit()
                            );

                            setTimeout(() => {
                              let batchArray3 = [];
                              batchArray3.push(writeBatch(db));
                              let batchCount3 = 0;
                              let batchIndex3 = 0;
                              let number3 = 0 + posts2.length - posts3.length;
                              Rankupdate2.forEach((post) => {
                                number3++;
                                console.log(number3);
                                post.forEach((element) => {
                                  if (post.length > 0) {
                                    const userDocumentRef = doc(
                                      db,
                                      "users",
                                      element.from !== element.id
                                        ? element.from
                                        : element.to,
                                      "Message",
                                      element.id
                                    );
                                    batchArray3[batchIndex3].update(
                                      userDocumentRef,
                                      {
                                        MessageRank: number3,
                                      }
                                    );
                                    batchCount3++;

                                    if (batchCount3 === 499) {
                                      batchArray3.push(writeBatch(db));
                                      batchIndex3++;
                                      batchCount3 = 0;
                                    }
                                  }
                                });
                              });

                              batchArray3.forEach(
                                async (batch) => await batch.commit()
                              );
                            }, 200);

                            let batchArray4 = [];
                            batchArray4.push(writeBatch(db));
                            let batchCount4 = 0;
                            let batchIndex4 = 0;
                            let number4 = 0 + posts2.length - posts3.length;
                            Rankupdate3.forEach((post) => {
                              number4++;
                              post.forEach((element) => {
                                if (post.length > 0) {
                                  const userDocumentRef = doc(
                                    db,
                                    "users",
                                    element.Redropper_id,
                                    "Drops",
                                    element.id
                                  );
                                  batchArray4[batchIndex4].update(
                                    userDocumentRef,
                                    {
                                      Rank: number4,
                                    }
                                  );
                                  batchCount4++;

                                  if (batchCount4 === 499) {
                                    batchArray4.push(writeBatch(db));
                                    batchIndex4++;
                                    batchCount4 = 0;
                                  }
                                }
                              });
                            });

                            batchArray4.forEach(
                              async (batch) => await batch.commit()
                            );

                            setTimeout(() => {
                              let batchArray5 = [];
                              batchArray5.push(writeBatch(db));
                              let batchCount5 = 0;
                              let batchIndex5 = 0;
                              let number5 = 0 + posts2.length - posts3.length;
                              Rankupdate4.forEach((post) => {
                                number5++;
                                post.forEach((element) => {
                                  if (post.length > 0) {
                                    const userDocumentRef = doc(
                                      db,
                                      "users",
                                      element.Redropper_id
                                        ? element.Directory
                                        : element.Sent_id &&
                                          element.Loadtype === "Drops"
                                        ? element.Sent_id
                                        : element.Sent_id &&
                                          element.Loadtype === "Comments"
                                        ? element.notification_reciever
                                        : element.Followed_id,

                                      "Notification",
                                      element.id
                                    );
                                    batchArray5[batchIndex5].update(
                                      userDocumentRef,
                                      {
                                        Rank: number5,
                                      }
                                    );
                                    batchCount5++;

                                    if (batchCount5 === 499) {
                                      batchArray5.push(writeBatch(db));
                                      batchIndex5++;
                                      batchCount5 = 0;
                                    }
                                  }
                                });
                              });

                              batchArray5.forEach(
                                async (batch) => await batch.commit()
                              );
                            }, 1000);

                            let batchArray6 = [];
                            batchArray6.push(writeBatch(db));
                            let batchCount6 = 0;
                            let batchIndex6 = 0;
                            let number6 = 0 + posts2.length - posts3.length;
                            Rankupdate5.forEach((post) => {
                              number6++;
                              post.forEach((element) => {
                                if (post.length > 0) {
                                  const userDocumentRef = doc(
                                    db,
                                    "users",
                                    element.Directory,
                                    "Following",
                                    element.id
                                  );
                                  batchArray6[batchIndex6].update(
                                    userDocumentRef,
                                    {
                                      Rank: number6,
                                    }
                                  );
                                  batchCount6++;

                                  if (batchCount6 === 499) {
                                    batchArray6.push(writeBatch(db));
                                    batchIndex6++;
                                    batchCount6 = 0;
                                  }
                                }
                              });
                            });

                            batchArray6.forEach(
                              async (batch) => await batch.commit()
                            );
                          }, 1000);
                        }
                      }
                    });
                  }
                }, 1500);
              }
            }
          });
        });
      }
    });
  }, [redropswitch]);

  const konbini = async () => {
    /*maybe use usecontext? */
    /*
    const userCollectionRef1 = collection(
      db,
      "users",
      userid,
      "OutstandingPay"
    );
    await getDocs(userCollectionRef1).then((querySnapshot) => {
      let posts2 = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      console.log(posts2);

      posts2 = posts2.filter((post) => post.For === "Rank");

      posts2.forEach((post) => {
        (async () => {
          console.log(post.Stripe_id);
          try {
            const response = await axios.get(
              `http://localhost:4000/v1/payment_intents/${post.Stripe_id}`,
              {
                params: {
                  data: post.Stripe_id,
                },
              }
            );

            if (response.data.success) {
              console.log(response.data);

              if (
                response.data.status === "succeeded" ||
                response.data.status === "requires_payment_method"
              ) {
                const userDocumentRef = doc(
                  db,
                  "users",
                  userid,
                  "OutstandingPay",
                  post.id
                );
                await deleteDoc(userDocumentRef);
              }

              if (response.data.status === "succeeded") {
                updateDoc(doc(db, "users", userid), {
                  Total_Currency_Generated: increment(post.Price),
                });




  let simulatetarget = JSON.parse(JSON.stringify(allusers));

 
    simulatetarget[
      (userinfo ? userinfo.Rank : location.state[0].Rank) - 1
    ].Total_Currency_Generated =
      allusers[
        (userinfo ? userinfo.Rank : location.state[0].Rank) - 1
      ].Total_Currency_Generated;
 
    simulatetarget[
      (userinfo ? userinfo.Rank : location.state[0].Rank) - 1
    ].Total_Currency_Generated =
      allusers[(userinfo ? userinfo.Rank : location.state[0].Rank) - 1]
        .Total_Currency_Generated +
      parseInt(e.target.value) * 2;
  

  console.log(simulatetarget);
  simulatetarget = simulatetarget.sort(
    (a, b) => b.Total_Currency_Generated - a.Total_Currency_Generated
  );

  console.log(simulatetarget);

  setNewrank(
    simulatetarget.findIndex((element) => location.state[0].id === element.id) +
      1
  );
  setNewallusers(simulatetarget);
  console.log(newallusers);












                if (Newrank !== Rank) {


                  let slicedallusers = Allusers.slice(Newrank - 1, Rank);

                  console.log(slicedallusers);

                  let Rankupdate = [];
                  slicedallusers.forEach((post) => {
                    const RankRef = collection(db, "users", post.id, "Drops");
                    getDocs(RankRef).then((querySnapshot) => {
                      let Rankposts = querySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                      }));
                      console.log(Rankposts);
                      let Rankposts2 = Rankposts.filter(
                        (element) =>
                          (!element.Sender_id &&
                            element.Directory === post.id) ||
                          element.Sender_id === post.id
                      );
                      Rankupdate.push(Rankposts2);
                    });
                  });

                  let Rankupdate1 = [];
                  slicedallusers.forEach((post) => {
                    const RankRef = collection(
                      db,
                      "users",
                      post.id,
                      "Comments"
                    );
                    getDocs(RankRef).then((querySnapshot) => {
                      let Rankposts1 = querySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                      }));
                      console.log(Rankposts1);
                      Rankupdate1.push(Rankposts1);
                      console.log(Rankupdate1);
                    });
                  });

                  let Rankupdate2 = [];
                  slicedallusers.forEach((post) => {
                    const RankRef = query(
                      collectionGroup(db, "Message"),
                      where("from", "==", post.id)
                    );
                    getDocs(RankRef).then((querySnapshot) => {
                      let Rankposts2 = querySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                      }));
                      console.log(Rankposts2);
                      Rankupdate2.push(Rankposts2);
                      console.log(Rankupdate2);
                    });
                  });

                  let Rankupdate3 = [];
                  slicedallusers.forEach((post) => {
                    const RankRef = query(
                      collectionGroup(db, "Drops"),
                      where("Redropper_id", "!=", false),
                      where("Sender_id", "==", post.id)
                    );
                    getDocs(RankRef).then((querySnapshot) => {
                      let Rankposts3 = querySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                      }));

                      const RankRef1 = query(
                        collectionGroup(db, "Drops"),
                        where("Redropper_id", "!=", false),
                        where("Directory", "==", post.id)
                      );
                      getDocs(RankRef1).then((querySnapshot) => {
                        let Rankposts4 = querySnapshot.docs.map((doc) => ({
                          ...doc.data(),
                          id: doc.id,
                        }));

                        let FilteredRankposts = Rankposts4.filter(
                          (post) => !post.Sender_id
                        );

                        console.log(Rankposts3);
                        console.log(FilteredRankposts);
                        let ReRankposts = [...Rankposts3, ...FilteredRankposts];
                        console.log(ReRankposts);
                        setTimeout(() => {
                          Rankupdate3.push(ReRankposts);
                          console.log(Rankupdate3);
                        }, 100);
                      });
                    });
                  });

                  let Rankupdate4 = [];
                  slicedallusers.forEach((post) => {
                    const RankRef = query(
                      collectionGroup(db, "Notification"),
                      where("Sender_id", "==", post.id)
                    );
                    getDocs(RankRef).then((querySnapshot) => {
                      let Rankposts5 = querySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                      }));

                      console.log(Rankposts5);

                      const RankRef1 = query(
                        collectionGroup(db, "Notification"),
                        where("Type", "==", "Follow"),
                        where("Directory", "==", post.id)
                      );
                      getDocs(RankRef1).then((querySnapshot) => {
                        let Rankposts6 = querySnapshot.docs.map((doc) => ({
                          ...doc.data(),
                          id: doc.id,
                        }));

                        console.log(Rankposts6);

                        const RankRef2 = query(
                          collectionGroup(db, "Notification"),
                          where("Redropper_id", "==", post.id)
                        );
                        getDocs(RankRef2).then((querySnapshot) => {
                          let Rankposts7 = querySnapshot.docs.map((doc) => ({
                            ...doc.data(),
                            id: doc.id,
                          }));

                          console.log(Rankposts7);
                          setTimeout(() => {
                            Rankupdate4.push([
                              ...Rankposts5,
                              ...Rankposts6,
                              ...Rankposts7,
                            ]);
                            console.log(Rankupdate4);
                          }, 100);
                        });
                      });
                    });
                  });

                  let Rankupdate5 = [];
                  slicedallusers.forEach((post) => {
                    const RankRef = query(
                      collectionGroup(db, "Following"),
                      where("Directory", "==", post.id)
                    );
                    getDocs(RankRef).then((querySnapshot) => {
                      let Rankposts6 = querySnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                      }));

                      console.log(Rankposts6);
                    });
                  });

                  let batchArray = [];
                  batchArray.push(writeBatch(db));
                  let batchCount = 0;
                  let batchIndex = 0;
                  let number = 0;

                  console.log(slicedallusers);
                  setTimeout(() => {
                    console.log(slicedallusers);

                    slicedallusers.forEach((post) => {
                      const userDocumentRef = doc(db, "users", post.id);
                      batchArray[batchIndex].update(userDocumentRef, {
                        Rank: Newrank + number,
                      });

                      batchCount++;
                      number++;

                      if (batchCount === 499) {
                        batchArray.push(writeBatch(db));
                        batchIndex++;
                        batchCount = 0;
                      }
                    });
                    batchArray.forEach(async (batch) => await batch.commit());

                    let batchArray1 = [];
                    batchArray1.push(writeBatch(db));
                    let batchCount1 = 0;
                    let batchIndex1 = 0;
                    let number1 = -1;

                    Rankupdate.forEach((post) => {
                      number1++;
                      console.log(number1);
                      post.forEach((element) => {
                        if (post.length > 0) {
                          const userDocumentRef = doc(
                            db,
                            "users",
                            element.Sender_id
                              ? element.Sender_id
                              : element.Directory,
                            "Drops",
                            element.id
                          );
                          batchArray1[batchIndex1].update(userDocumentRef, {
                            Rank: Newrank + number1,
                          });

                          batchCount1++;

                          if (batchCount1 === 499) {
                            batchArray1.push(writeBatch(db));
                            batchIndex1++;
                            batchCount1 = 0;
                          }
                        }
                      });
                    });

                    batchArray1.forEach(async (batch) => await batch.commit());

                    let batchArray2 = [];
                    batchArray2.push(writeBatch(db));
                    let batchCount2 = 0;
                    let batchIndex2 = 0;
                    let number2 = -1;
                    Rankupdate1.forEach((post) => {
                      number2++;
                      console.log(number2);
                      post.forEach((element) => {
                        if (post.length > 0) {
                          const userDocumentRef = doc(
                            db,
                            "users",
                            element.Sender_id,
                            "Comments",
                            element.id
                          );
                          batchArray2[batchIndex2].update(userDocumentRef, {
                            Rank: Newrank + number2,
                          });
                          batchCount2++;

                          if (batchCount2 === 499) {
                            batchArray2.push(writeBatch(db));
                            batchIndex2++;
                            batchCount2 = 0;
                          }
                        }
                      });
                    });

                    batchArray2.forEach(async (batch) => await batch.commit());

                    let batchArray3 = [];
                    batchArray3.push(writeBatch(db));
                    let batchCount3 = 0;
                    let batchIndex3 = 0;
                    let number3 = -1;
                    Rankupdate2.forEach((post) => {
                      number3++;
                      console.log(number3);
                      post.forEach((element) => {
                        if (post.length > 0) {
                          const userDocumentRef = doc(
                            db,
                            "users",
                            element.from !== element.id
                              ? element.from
                              : element.to,
                            "Message",
                            element.id
                          );
                          batchArray3[batchIndex3].update(userDocumentRef, {
                            MessageRank: Newrank + number3,
                          });
                          batchCount3++;

                          if (batchCount3 === 499) {
                            batchArray3.push(writeBatch(db));
                            batchIndex3++;
                            batchCount3 = 0;
                          }
                        }
                      });
                    });

                    batchArray3.forEach(async (batch) => await batch.commit());

                    let batchArray4 = [];
                    batchArray4.push(writeBatch(db));
                    let batchCount4 = 0;
                    let batchIndex4 = 0;
                    let number4 = -1;
                    Rankupdate3.forEach((post) => {
                      number4++;
                      post.forEach((element) => {
                        if (post.length > 0) {
                          const userDocumentRef = doc(
                            db,
                            "users",
                            element.Redropper_id,
                            "Drops",
                            element.id
                          );
                          batchArray4[batchIndex4].update(userDocumentRef, {
                            Rank: Newrank + number4,
                          });
                          batchCount4++;

                          if (batchCount4 === 499) {
                            batchArray4.push(writeBatch(db));
                            batchIndex4++;
                            batchCount4 = 0;
                          }
                        }
                      });
                    });

                    batchArray4.forEach(async (batch) => await batch.commit());

                    setTimeout(() => {
                      let batchArray5 = [];
                      batchArray5.push(writeBatch(db));
                      let batchCount5 = 0;
                      let batchIndex5 = 0;
                      let number5 = -1;
                      Rankupdate4.forEach((post) => {
                        number5++;
                        post.forEach((element) => {
                          if (post.length > 0) {
                            const userDocumentRef = doc(
                              db,
                              "users",
                              element.Redropper_id
                                ? element.Directory
                                : element.Sent_id &&
                                  element.Loadtype === "Drops"
                                ? element.Sent_id
                                : element.Sent_id &&
                                  element.Loadtype === "Comments"
                                ? element.notification_reciever
                                : element.Followed_id,

                              "Notification",
                              element.id
                            );
                            batchArray5[batchIndex5].update(userDocumentRef, {
                              Rank: Newrank + number5,
                            });
                            batchCount5++;

                            if (batchCount5 === 499) {
                              batchArray5.push(writeBatch(db));
                              batchIndex5++;
                              batchCount5 = 0;
                            }
                          }
                        });
                      });

                      batchArray5.forEach(
                        async (batch) => await batch.commit()
                      );
                    }, 1000);

                    let batchArray6 = [];
                    batchArray6.push(writeBatch(db));
                    let batchCount6 = 0;
                    let batchIndex6 = 0;
                    let number6 = -1;
                    Rankupdate5.forEach((post) => {
                      number6++;
                      post.forEach((element) => {
                        if (post.length > 0) {
                          const userDocumentRef = doc(
                            db,
                            "users",
                            element.Directory,
                            "Following",
                            element.id
                          );
                          batchArray6[batchIndex6].update(userDocumentRef, {
                            Rank: Newrank + number6,
                          });
                          batchCount6++;

                          if (batchCount6 === 499) {
                            batchArray6.push(writeBatch(db));
                            batchIndex6++;
                            batchCount6 = 0;
                          }
                        }
                      });
                    });

                    batchArray6.forEach(async (batch) => await batch.commit());
                  });
                } else if (response.data.status === "requires_payment_method") {
                  updateDoc(
                    doc(
                      db,
                      "users",
                      post.Directory,
                      "PledgePlan",
                      post.Pledgeid
                    ),
                    {
                      subscribers: increment(-1),
                    }
                  );
                }
              }
            }
          } catch (error) {
            console.log(error);
          }
        })();
      });
    });
    */
  };

  const pconfirm = async () => {
    let url;
    const storage = getStorage();

    let finalratio = cropper.getCroppedCanvas().toDataURL().slice(22);
    console.log(finalratio);
    const storageRef = ref(
      storage,
      `images/${finalratio.slice(23, 30) + new Date().getTime()}.png`
    );

    await uploadString(storageRef, finalratio, "base64", {
      contentType: "image/png",
    }).then((uploadtask) => {});
    await getDownloadURL(storageRef).then((downloadurl) => {
      url = downloadurl;
      console.log(url);

      setUserinfo((prevvalue) => ({
        ...prevvalue,
        Profile_image: url,
      }));

      setImage("");

      //maybe forEach?
      posts.map((post) => {
        if (
          (!post.Sender_id && post.Directory === id) ||
          post.Sender_id === id
        ) {
          post.Profile_image = url;
        }
      });
    });

    if (userinfo.Profile_image !== "/Profile3.svg") {
      const oldRef = ref(storage, userinfo.Profile_image);
      deleteObject(oldRef);
    }

    try {
      updateDoc(doc(db, "users", id), {
        Profile_image: url,
      });
    } catch (error) {
      console.log(error);
    }

    //keep this here since we might have to get all files since we might not load every post
    //
    const userCollectionRef = collection(db, "users", id, "Drops");
    getDocs(userCollectionRef).then((querySnapshot) => {
      let posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let new_posts = posts.filter(
        (post) =>
          (!post.Sender_id && post.Directory === id) || post.Sender_id === id
      );
      console.log(posts);
      console.log(new_posts);
      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      new_posts.forEach((post) => {
        const userDocumentRef = doc(db, "users", id, "Drops", post.id);
        batchArray[batchIndex].update(userDocumentRef, {
          Profile_image: url,
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    //set the new_posts as the new drop we are getting the docs here anyways
    //so no need to manually set the docs with of profile image to url

    const userCollectionRef2 = collection(db, "users", id, "Comments");
    getDocs(userCollectionRef2).then((querySnapshot) => {
      let posts1 = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      console.log(posts1);

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      posts1.forEach((post) => {
        const userDocumentRef = doc(db, "users", id, "Comments", post.id);
        batchArray[batchIndex].update(userDocumentRef, {
          Profile_image: url,
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    //only get other peoples redropped tweet that are yours
    const dropsRef = query(
      collectionGroup(db, "Drops"),
      where("Directory", "==", id),
      where("Redropper_id", "!=", id)
    );

    getDocs(dropsRef).then((querySnapshot) => {
      let posts2 = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      console.log(posts2);

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      posts2.forEach((post) => {
        const userDocumentRef = doc(
          db,
          "users",
          post.Redropper_id,
          "Drops",
          post.id
        );
        batchArray[batchIndex].update(userDocumentRef, {
          Profile_image: url,
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    const dropsRef1 = query(
      collectionGroup(db, "Drops"),
      where("Sender_id", "==", id),
      where("Redropper_id", "!=", id)
    );

    getDocs(dropsRef1).then((querySnapshot) => {
      let posts2 = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      posts2.forEach((post) => {
        const userDocumentRef = doc(
          db,
          "users",
          post.Redropper_id,
          "Drops",
          post.id
        );
        batchArray[batchIndex].update(userDocumentRef, {
          Profile_image: url,
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    /*const dropsRef2 = query(
          collectionGroup(db, "Message"),
          where("from", "==", id) || where("to", "==", id)
        );
*/

    const dropsRef2 = query(
      collectionGroup(db, "Message"),
      where("from", "==", id)
    );

    const dropsRef3 = query(
      collectionGroup(db, "Message"),
      where("to", "==", id)
    );

    let posts3;
    let posts4;
    let posts5;
    let promises = [];
    /* new Promise((resolve, reject) => {
          //maybe try it without using resolve?
          resolve(
            getDocs(dropsRef2).then((querySnapshot) => {
              posts3 = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }));
            }),

            getDocs(dropsRef3).then((querySnapshot) => {
              posts4 = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }));
            })
           
          );
        });
        */
    promises.push(
      new Promise((resolve, reject) => {
        //maybe try it without using resolve?
        resolve(
          getDocs(dropsRef2).then((querySnapshot) => {
            posts3 = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
          })
        );
      })
    );
    promises.push(
      new Promise((resolve, reject) => {
        resolve(
          getDocs(dropsRef3).then((querySnapshot) => {
            posts4 = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
          })
        );
      })
    );
    await Promise.all(promises).then(() => {
      //await for the filter to finish as well

      console.log(posts3);
      console.log(posts4);
      posts5 = [...posts3, ...posts4];
      //definitely need to filter here or youll have your own messages as well
      posts5 = posts5.filter((post) => post.id === id);
      console.log(posts5);
    });

    setTimeout(() => {
      console.log(posts5);
      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      posts5.forEach((post) => {
        const userDocumentRef = doc(
          db,
          "users",
          //cleaner way to get this? userid from filtered posts3
          post.from === id ? post.to : post.from,
          "Message",
          post.id
        );
        batchArray[batchIndex].update(userDocumentRef, {
          MessagePic: url,
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });

    //notification update
    //follow comment redrop

    let notificationposts = [];
    let notificationpromises = [];
    const notificationfollowRef = query(
      collectionGroup(db, "Notification"),
      where("Type", "==", "Follow"),
      where("Directory", "==", id)
    );

    const notificationcommentRef = query(
      collectionGroup(db, "Notification"),
      where("Type", "==", "Comment"),
      where("Sender_id", "==", id)
    );

    const notificationredropRef = query(
      collectionGroup(db, "Notification"),
      where("Type", "==", "Redrop"),
      where("Redropper_id", "==", id)
    );

    //or simple await may be fine

    notificationpromises.push(
      getDocs(notificationfollowRef).then((querySnapshot) => {
        notificationposts = [
          ...notificationposts,
          ...querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          })),
        ];
      })
    );

    notificationpromises.push(
      getDocs(notificationcommentRef).then((querySnapshot) => {
        notificationposts = [
          ...notificationposts,
          ...querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          })),
        ];
      })
    );

    notificationpromises.push(
      getDocs(notificationredropRef).then((querySnapshot) => {
        notificationposts = [
          ...notificationposts,
          ...querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          })),
        ];
      })
    );

    await Promise.all(notificationpromises).then(() => {
      //this is important, make sure that all objects are nicely layed out in this array
      console.log(notificationposts);

      let batchArray = [];
      batchArray.push(writeBatch(db));
      let batchCount = 0;
      let batchIndex = 0;
      notificationposts.forEach((post) => {
        const userDocumentRef = doc(
          db,
          "users",
          post.Type === "Follow"
            ? post.Followed_id
            : post.Type === "Comment"
            ? post.Sent_id
            : post.Type === "Redrop" && post.Loadtype !== "NestedComments"
            ? post.Directory
            : post.Type === "Redrop" &&
              post.Loadtype === "NestedComments" &&
              post.Nested_user,
          "Notification",
          post.id
        );
        batchArray[batchIndex].update(userDocumentRef, {
          Profile_image: url,
        });
        batchCount++;
        if (batchCount === 499) {
          batchArray.push(writeBatch(db));
          batchIndex++;
          batchCount = 0;
        }
      });

      batchArray.forEach(async (batch) => await batch.commit());
    });
  };

  const UploadHead = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log(reader.result);
      setHeadfiles({
        file: reader.result,
        id: e.target.files[0].name + new Date().getTime(),
        realfile: e.target.files[0],
      });
    };
  };

  const pheadconfirm = async () => {
    const storage = getStorage();
    // `images/${file.name + new Date().getTime()}
    const storageRef = ref(storage, `images/${headfiles.id}`);
    const uploadTask = uploadBytesResumable(storageRef, headfiles.realfile);
    uploadTask.on("state_changed", (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    });
    let url;
    uploadTask.then(() => {
      getDownloadURL(storageRef).then((downloadurl) => {
        console.log(downloadurl);
        url = downloadurl;
        setUserinfo((prevvalue) => ({
          ...prevvalue,
          Head_image: url,
        }));

        setTimeout(() => {
          setHeadfiles("");
        });

        try {
          updateDoc(doc(db, "users", id), {
            Head_image: url,
          });
        } catch (error) {
          console.log(error);
        }
      });
    });

    const oldRef = ref(storage, userinfo.Head_image);
    if (userinfo.Head_image) {
      deleteObject(oldRef)
        .then(() => {})
        .catch((error) => {});
    }
  };

  const headcancel = () => {
    setHeadfiles("");
  };

  const Out = async () => {
    await signOut(auth);
    navigate(`/`);
  };

  const exposedrop = () => {
    setDropView(true);
  };

  const introsave = () => {};

  const confirmintro = async () => {
    try {
      await updateDoc(doc(db, "users", id), {
        Intro: userinfo.Intro,
      });
      setTempintro1(userinfo.Intro);
      setTempintro(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelintro = () => {
    setUserinfo((prevvalue) => ({
      ...prevvalue,
      Intro: tempintro1,
    }));

    setTempintro(false);
  };

  const AskUnredrop = (e) => {
    setUnredrop(true);
    let unredropidpost = e.target.id.split(",");
    setUnredropid1(unredropidpost[0]);
    setUnredropd(unredropidpost[1]);
    setUnredropdid(unredropidpost[2]);
    console.log(unredropidpost[2]);
  };

  const ConfirmUnredrop = async () => {
    const userDocumentRef = doc(db, "users", id, "Drops", unredropid1);
    await deleteDoc(userDocumentRef);
    updateDoc(doc(db, `users/${unredropd}/Drops/${unredropdid}`), {
      Redropped_Counter: increment(-1),
    });
    setRedropswitch(!redropswitch);
    setUnredrop(false);
  };

  const Introdecide = (e) => {
    setUserinfo((prevvalue) => ({
      ...prevvalue,
      Intro: e.target.value,
    }));

    setTempintro(true);
    if (backspaceslide === false && enterlimit.length < 1) {
      pressnumber.push(1);
      console.log(pressnumber.length);
    } else if (enterlimit.length === 1 && backspaceslide === true) {
      pressnumber1.push(1);
      console.log(pressnumber1.length);
    } else if (enterlimit.length === 2 && newline === true) {
      pressnumber2.push(1);
      console.log(pressnumber2.length);
    }
  };

  const checkenter = (e) => {
    if (e.key === "Enter" && pressnumber.length === 0) {
      e.preventDefault();
    } else if (
      e.key === "Enter" &&
      enterlimit.length === 1 &&
      pressnumber1.length === 0
    ) {
      e.preventDefault();
    } else if (e.key === "Backspace" && enterlimit.length === 0) {
      setTimeout(() => {
        pressnumber.pop();
        pressnumber.pop();
        console.log(pressnumber.length);
      });
    } else if (e.key === "Enter" && enterlimit.length < 2) {
      if (enterlimit.length === 0) {
        setTimeout(() => {
          setBackspaceslide(true);

          console.log(pressnumber1.length);
        });
      } else if (enterlimit.length === 1) {
        setTimeout(() => {
          setNewline(true);

          console.log(pressnumber2.length);
        });
      }
      enterlimit.push(1);
      console.log(enterlimit.length);
    } else if (enterlimit.length === 2 && e.key === "Enter") {
      e.preventDefault();
    } else if (e.key === "Backspace" && pressnumber2.length > 0) {
      setTimeout(() => {
        pressnumber2.pop();
        pressnumber2.pop();
        console.log(pressnumber2.length);
      });
    } else if (e.key === "Backspace" && pressnumber1.length === 0) {
      setTimeout(() => {
        enterlimit.pop();
        setBackspaceslide(false);
        console.log(enterlimit);
        pressnumber1.pop();
      });
    } else if (
      e.key === "Backspace" &&
      pressnumber1.length > 0 &&
      pressnumber2.length === 0 &&
      backspaceslide === true &&
      newline === false
    ) {
      setTimeout(() => {
        pressnumber1.pop();
        pressnumber1.pop();
        console.log(pressnumber1);
      });
    } else if (
      e.key === "Backspace" &&
      pressnumber2.length === 0 &&
      newline === true
    ) {
      setTimeout(() => {
        enterlimit.pop();
        setNewline(false);
        console.log("hey");
        pressnumber2.pop();
      });
    } else if (e.key === "Backspace" && pressnumber2.length > 0) {
      setTimeout(() => {
        pressnumber2.pop();
        pressnumber2.pop();
        console.log(pressnumber2.length);
      });
    }
  };

  const locationchange = () => {};

  const getCropdata = () => {
    if (typeof cropper !== "undefined") {
      console.log(cropper.getCroppedCanvas());
      setCropdata(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const photoedit = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    setPhotourl(reader.readAsDataURL(files[0]));
  };

  const redrop = () => {};

  const comment = () => {};
  const display = () => {};

  const imagehtml = (post, post1, post2) => {
    console.log(post);
    console.log(post1);
    const images = {
      width: 0,
      height: 0,
      marginLeft: 0,
      marginTop: 0,
    };
    //if (post1.id === ){}

    images.width = post2?.path
      ? 130
      : post2?.path !== "" && post2?.path !== undefined
      ? 110
      : ((post1?.path !== "" && post1?.path !== undefined) ||
          post?.path !== "") &&
        150;
    images.height =
      post2?.path !== "" && post2?.path !== undefined
        ? 95
        : post1?.path !== "" && post2?.path === ""
        ? 195
        : post?.path !== "" && 150;

    images.marginLeft =
      post1?.path !== "" && post2?.path === "" ? 20 : post?.path !== "" && 5;
    images.marginTop = post?.path !== "" && 20;

    return images;
  };

  const videohtml = (post, post1, post2) => {
    /*
      controls: tempfiles.length < 2 && true,
      width: fullscreened ? "100%" : tempfiles.length === 1 ? 200 : 175,
      height: fullscreened ? "40%" : tempfiles.length === 1 ? "200" : 175,
      playsInline,
      autoPlay,
      muted,
      loop,
      */

    const videos = {
      objectFit: "cover",
      borderRadius: 20,
      marginLeft:
        !fullscreened && post1?.path !== "" && post2?.path === ""
          ? 20
          : post?.path && !fullscreened && 5,
      marginTop: post?.path !== "" && 20,
      position: fullscreened && "absolute",
      top: fullscreened && "30%",
    };
    return videos;
  };

  const determinevideoref = (post, post1, post2, index) => {
    let finalvideos = [];
    let num = 0;

    let videoornot = [];

    post === "videos" && videoornot.push("videos");
    post1 === "videos" && videoornot.push("videos");
    post2 === "videos" && videoornot.push("videos");

    if (videoornot.length === 1) {
      finalvideos[0] = droppedvideoref.current[index];
    } else {
      for (const video of videoornot) {
        if (video === "videos") {
          finalvideos.push(droppedvideoref.current[index][num]);
          num++;
        }
      }
    }
    return finalvideos;
  };

  const Closefullscreen = (e) => {
    setFullscreentarget([]);
  };

  return (
    <div style={{ ...Fullscreenstyleson }} className="userpage">
      {userid === id ? (
        <>
          {rankupanimation && (
            /*ランクアップ👑*/ <div
              style={{
                background: "linear-gradient(#bd5fbd, #42b1e0)",
                display: "flex",
                justifyContent: "space-around",
                fontSize: 40,
                width: "100%",
                position: "absolute",
                top: "50%",
              }}
            >
              <div style={{ color: "aqua" }}>{userinfo.PreviousRank}</div>{" "}
              <img style={{ transform: "scaleX(-1)" }} src="/Arrow1.svg" />
              <div style={{ color: "gold" }}>{userinfo.Rank}</div>
            </div>
          )}

          {fullscreentarget.length > 0 && (
            <div
              style={{
                position: "fixed",

                width: "100%",
                height: "100%",
                //overflowX: "clip",
                zIndex: 2,
              }}
            >
              {fullscreentarget[1] === "image" ? (
                <FullScreenImage
                  file={fullscreentarget[0]}
                  // type={fullscreentarget[1]}
                  close={Closefullscreen}
                  shape={fullscreentarget[2]}
                />
              ) : (
                fullscreentarget[1] === "video" && (
                  <FullScreenVideo
                    file={fullscreentarget[0]}
                    // type={fullscreentarget[1]}
                    close={Closefullscreen}
                    shape={fullscreentarget[2]}
                  />
                )
              )}
            </div>
          )}

          {!option ? (
            <img
              className="option1"
              src="/option.svg"
              onClick={(e) => setOption(true)}
            />
          ) : (
            <img
              className="option2"
              onClick={(e) => setOption(false)}
              src="/Tog1.svg"
            />
          )}
          {headfiles?.file ? (
            <>
              <img className="testpic1" src={headfiles?.file} />
              <div className="Pselect">
                <button
                  style={{ top: 60, width: 40, marginLeft: 42 }}
                  onClick={pheadconfirm}
                >
                  〇
                </button>
                <button style={{ top: 60, width: 40 }} onClick={headcancel}>
                  X
                </button>
              </div>
            </>
          ) : (
            <label>
              {!userinfo.Head_image ? (
                <div className="testpic1"></div>
              ) : (
                <img src={userinfo.Head_image} className="testpic1" />
              )}
              <input className="changepic" type="file" onChange={UploadHead} />
            </label>
          )}
          <div className="usernameroot">
            <div className="username">
              <div className="username1">{userinfo.User_Name}</div>
            </div>
          </div>
          <div className="IntroLine">
            <div className="bothlocation">
              <img src="/Location.svg" className="location" />
              <span className="locationtext" onClick={locationchange}>
                {userinfo.Location ? userinfo.Location : "Location"}
              </span>
              <img src="/Location.svg" className="location" />
            </div>
            <div className="bothintros">
              <textarea
                type="text"
                placeholder={!userinfo.Intro ? "自己紹介..." : "false"}
                className="introinput1"
                value={userinfo.Intro}
                onChange={Introdecide}
                spellCheck="false"
                onKeyDown={checkenter}
              />
            </div>
            {tempintro && (
              <div className="Pselect">
                <button
                  style={{ bottom: 450, width: 40, marginLeft: 42 }}
                  onClick={confirmintro}
                >
                  〇
                </button>
                <button
                  style={{ bottom: 450, width: 40 }}
                  onClick={cancelintro}
                >
                  X
                </button>
              </div>
            )}
          </div>
          <hr />

          {option && (
            <div className="optiongroup">
              <img
                className="cash"
                src="/Cash.svg"
                onClick={(e) => navigate(`/Currency`)}
              />

              <hr />
              <Link to={"/UserSettings"}>
                <img className="UserOp" src="/UserOption.svg" />
              </Link>
              <hr />
              <button className="Signout" onClick={Out}>
                <p> ログアウト </p>
              </button>
            </div>
          )}
          <div className="MiddleProfile">
            {image ? (
              <>
                <div>
                  <Cropper
                    className="editpic"
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    src={image}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                      setCropper(instance);
                    }}
                    guides={true}
                  />
                </div>
                {cropdata && <img className="Profile3" src={cropdata} />}

                <div className="Pselect">
                  <button onClick={pconfirm}>〇</button>
                  <button onClick={(e) => setImage("")}>X</button>
                </div>
              </>
            ) : (
              <>
                <label>
                  <img className="Profile3" src={userinfo.Profile_image} />
                  <input
                    className="changepic"
                    type="file"
                    onChange={photoedit}
                  />
                </label>
              </>
            )}

            <div className="info">
              <img
                className="rankd"
                src="/Rank.svg"
                onClick={(e) => navigate(`/Ranks`, { state: [userinfo, id] })}
              />

              <hr />

              <div
                className={userinfo.Rank < 10 ? "rankn1" : "rankn"}
                onClick={(e) => navigate(`/Ranks`, { state: [userinfo, id] })}
              >
                {userinfo.Rank}
              </div>

              <Link to={`/PledgeSettings`}>
                <img
                  className="pledge"
                  src={pledgegreen ? "/Pledge1.svg" : "/PledgeEmpty.svg"}
                />
              </Link>
              <hr />
              <span style={{ display: "flex", alignItems: "end" }}>
                <img className="Profile2" src="/Profile3.svg" />
                <span
                  style={{
                    marginLeft: 5,
                    color: "cornflowerblue",
                    fontSize: 20,
                  }}
                >
                  /
                </span>
                <span style={{ marginLeft: 4, color: "aqua", fontSize: 20 }}>
                  {followercount}
                </span>
              </span>
              <hr style={{ marginTop: 6 }} />
              <img className="camera" src="/Photo.svg" />
              <hr />
            </div>
          </div>
          <hr className="middle" />
          <div className="ClickOption">
            <Link to={`/Connect`}>
              <img className="ConnectIcon" src="/Connect.svg" />
            </Link>
          </div>
          <div className="Clickbottomoption">
            <img src="/DropView.svg" onClick={exposedrop} />
          </div>

          {posts.map((post, index) => (
            <div
              className="drops"
              key={post.id}
              style={{
                height:
                  (post.image2 && 310) ||
                  ((post.image1 ||
                    (post.file1?.path !== "" &&
                      post.file1?.path !== undefined)) &&
                    330),
              }}
            >
              <div className="postlinetop">
                <Link
                  to={`/UserView/${
                    post.Sender_id ? post.Sender_id : post.Directory
                  }`}
                >
                  <img
                    className={
                      post.Profile_image === "/Profile3.svg"
                        ? "current3"
                        : "current1"
                    }
                    src={post.Profile_image}
                  />
                </Link>

                {(post.image1 || post.file1?.path) && (
                  <div className={post.text?.length >= 23 ? "text" : "text1"}>
                    {post.text}
                  </div>
                )}
                <img className="postoption" src="/DropOption.svg" />
              </div>
              <span className="textline">
                <span
                  className="stylerank"
                  style={{
                    marginLeft:
                      post.Rank <= 9
                        ? 19.4
                        : post.Rank === 11
                        ? 16
                        : post.Rank <= 99
                        ? 14.5
                        : post.Rank > 99 && 10,
                    flex:
                      (post.text ? post.text : post.Comment)?.length >= 25
                        ? 0.18
                        : (post.text ? post.text : post.Comment)?.length === 20
                        ? 0.14
                        : 0.5,
                  }}
                >
                  {post.Rank}
                </span>

                {!post.image1 && !post.file1?.path && (
                  <div
                    className={
                      (post.text ? post.text : post.Comment)?.length >= 23
                        ? "text"
                        : "text1"
                    }
                  >
                    {post.text ? post.text : post.Comment}
                  </div>
                )}
              </span>
              <div
                className="alldrops"
                id={[post.id, post.Directory, post.Comment_Counter]}
                onClick={display}
                style={{
                  height:
                    (post.image2 && 275) ||
                    ((post.image1 || post.file1?.path) && 295),
                }}
              ></div>
              <div
                style={{
                  marginLeft:
                    !post.file3?.path && post.file2?.path
                      ? 40
                      : post.file3?.path && 60,
                  marginTop: post.file3?.path && -10,
                  justifyContent:
                    post.file3?.path && !post.file4?.path && "flex-start",
                }}
                className="postphotoline"
              >
                {post.file1?.filetype === "videos" ? (
                  <video
                    controls={!post.file2.path && true}
                    width={
                      fullscreened
                        ? "100%"
                        : post.file2.path === ""
                        ? 200
                        : post.file4.path !== ""
                        ? 130
                        : 150
                    }
                    height={
                      fullscreened
                        ? "40%"
                        : post.file2.path === ""
                        ? 200
                        : post.file4?.path !== ""
                        ? 95
                        : post.file3.path !== ""
                        ? 195
                        : 150
                    }
                    ref={(element) =>
                      (droppedvideoref.current[index] = element)
                    }
                    playsInline
                    muted
                    loop={!post.file2.path && true}
                    id={post.file1.id}
                    style={videohtml(post?.file2, post.file3, post.file4)}
                  >
                    <source type="video/mp4" src={post.file1?.path} />
                  </video>
                ) : (
                  post.file1?.filetype === "images" && (
                    <img
                      className="postphoto"
                      src={post.file1?.path}
                      id={post.file1?.id}
                      style={imagehtml(post.file2, post.file3, post.file4)}
                      onClick={() =>
                        setFullscreentarget([
                          post.file1?.path,
                          "image",
                          "square",
                        ])
                      }
                    />
                  )
                )}

                {!post.file4?.path ? (
                  <div
                    style={{
                      display: post.file3?.path && !post.file4?.path && "flex",
                      flexDirection:
                        post.file3?.path && !post.file4?.path && "column",
                    }}
                  >
                    {post.file2?.filetype === "videos" ? (
                      <video
                        width={
                          fullscreened ? "100%" : post.file3?.path ? 110 : 150
                        }
                        height={
                          fullscreened ? "40%" : post.file3?.path ? 95 : 150
                        }
                        ref={(element) =>
                          (droppedvideoref.current[index] = [
                            droppedvideoref.current[index],
                            element,
                          ])
                        }
                        playsInline
                        muted
                        id={post.file2.id}
                        style={videohtml(post.file1)}
                      >
                        <source type="video/mp4" src={post.file2?.path} />
                      </video>
                    ) : (
                      post.file2?.filetype === "images" && (
                        <img
                          className="postphoto"
                          src={post.file2?.path}
                          id={post.file2?.id}
                          onClick={() =>
                            setFullscreentarget([
                              post.file2?.path,
                              "image",
                              "square",
                            ])
                          }
                          style={
                            post.file3?.path
                              ? {
                                  ...imagehtml(post.file1),
                                  width: 110,
                                  height: post.file3.path && 95,
                                }
                              : imagehtml(post.file1)
                          }
                        />
                      )
                    )}

                    {post.file3?.filetype === "videos" ? (
                      <video
                        width={fullscreened ? "100%" : 110}
                        height={fullscreened ? "40%" : 95}
                        ref={(element) =>
                          (droppedvideoref.current[index] = [
                            droppedvideoref.current[index][0],
                            droppedvideoref.current[index][1],
                            element,
                          ])
                        }
                        playsInline
                        muted
                        id={post.file3.id}
                        style={{
                          ...videohtml(post.file1),
                          marginTop: 4,
                        }}
                      >
                        <source type="video/mp4" src={post.file3?.path} />
                      </video>
                    ) : (
                      post.file3?.filetype === "images" && (
                        <img
                          className="postphoto"
                          src={post.file3?.path}
                          id={post.file3?.id}
                          onClick={() =>
                            setFullscreentarget([
                              post.file3?.path,
                              "image",
                              "square",
                            ])
                          }
                          style={{
                            ...imagehtml(post.file1),
                            width: !post.file4?.path ? 110 : 130,
                            height: 95,
                            marginTop: 4,
                          }}
                        />
                      )
                    )}
                  </div>
                ) : (
                  <>
                    {post.file2?.filetype === "videos" ? (
                      <video
                        width={
                          fullscreened
                            ? "100%"
                            : post.file4.path !== ""
                            ? 130
                            : post.file3.path
                            ? 110
                            : 150
                        }
                        height={
                          fullscreened ? "40%" : post.file3.path ? 95 : 150
                        }
                        ref={(element) =>
                          (droppedvideoref.current[index] = [
                            droppedvideoref.current[index],
                            element,
                          ])
                        }
                        playsInline
                        muted
                        id={post.file2.id}
                        style={videohtml(post.file1)}
                      >
                        <source type="video/mp4" src={post.file2?.path} />
                      </video>
                    ) : (
                      post.file2?.filetype === "images" && (
                        <img
                          className="postphoto"
                          src={post.file2?.path}
                          id={post.file2?.id}
                          onClick={() =>
                            setFullscreentarget([
                              post.file2?.path,
                              "image",
                              "square",
                            ])
                          }
                          style={
                            post.file3?.path
                              ? {
                                  ...imagehtml(post.file1),
                                  width:
                                    (post.file4?.path && 130) ||
                                    (post.file3.path && 110),
                                  height: post.file3.path && 95,
                                }
                              : imagehtml(post.file1)
                          }
                        />
                      )
                    )}
                    {post.file3?.filetype === "videos" ? (
                      <video
                        width={
                          fullscreened
                            ? "100%"
                            : post.file4.path !== ""
                            ? 130
                            : 110
                        }
                        height={fullscreened ? "40%" : 95}
                        ref={(element) =>
                          (droppedvideoref.current[index] = [
                            droppedvideoref.current[index][0],
                            droppedvideoref.current[index][1],
                            element,
                          ])
                        }
                        playsInline
                        muted
                        id={post.file3.id}
                        style={{
                          ...videohtml(post.file1),

                          marginTop: 4,
                        }}
                      >
                        <source type="video/mp4" src={post.file3?.path} />
                      </video>
                    ) : (
                      post.file3?.filetype === "images" && (
                        <img
                          className="postphoto"
                          src={post.file3?.path}
                          id={post.file3?.id}
                          onClick={() =>
                            setFullscreentarget([
                              post.file3?.path,
                              "image",
                              "square",
                            ])
                          }
                          style={{
                            ...imagehtml(post.file1),
                            width: !post.file4?.path ? 110 : 130,
                            height: 95,
                            marginTop: 4,
                          }}
                        />
                      )
                    )}
                  </>
                )}

                {post.file4?.filetype === "videos" ? (
                  <video
                    width={
                      fullscreened ? "100%" : post.file4.path !== "" ? 130 : 110
                    }
                    height={fullscreened ? "40%" : 95}
                    ref={(element) =>
                      (droppedvideoref.current[index] =
                        post.file1.filetype === "videos" &&
                        post.file2.filetype === "videos" &&
                        post.file3.filetype === "videos"
                          ? [
                              droppedvideoref.current[index][0],
                              droppedvideoref.current[index][1],
                              droppedvideoref.current[index][2],
                              element,
                            ]
                          : post.file1.filetype !== "videos" &&
                            post.file2.filetype !== "videos" &&
                            post.file3.filetype !== "videos"
                          ? element
                          : post.file1.filetype !== "videos" ||
                            post.file2.filetype !== "videos" ||
                            post.file3.filetype !== "videos"
                          ? [
                              ...determinevideoref(
                                post.file1.filetype,
                                post.file2.filetype,
                                post.file3.filetype,
                                index
                              ),
                              element,
                            ]
                          : [])
                    }
                    playsInline
                    muted
                    id={post.file4.id}
                    style={{
                      ...videohtml(post.file1),

                      marginTop: 4,
                    }}
                  >
                    <source type="video/mp4" src={post.file4?.path} />
                  </video>
                ) : (
                  post.file4?.filetype === "images" && (
                    <img
                      src={post.file4?.path}
                      className="postphoto"
                      id={post.file4?.id}
                      onClick={() =>
                        setFullscreentarget([
                          post.file4?.path,
                          "image",
                          "square",
                        ])
                      }
                      style={{
                        ...imagehtml(post.file1),
                        width: 130,
                        height: 95,
                        marginTop: 4,
                      }}
                    />
                  )
                )}

                {post.image1 && (
                  <img
                    className="postphoto"
                    src={post.image1}
                    onClick={() =>
                      setFullscreentarget([post.image1, "image", "square"])
                    }
                    style={{
                      width: post.image2 && 150,
                      height: post.image2 && 150,
                      marginLeft: post.image2 && 50,
                      marginTop: post.image2 && 20,
                    }}
                  />
                )}
              </div>

              <div className="date">
                {post.year}/{post.month}/{post.date}
                <span className="Timesplit">|</span>
                <span className="Time">
                  {post.hour}:{post.minutes}
                </span>
              </div>
              <img
                className="Rankmarked"
                src="/RankMark.svg"
                style={{ top: (post.image1 || post.file1?.path) && 51 }}
              />
              <span className="dropoption">
                <span className="commentgroup">
                  <img
                    className="commenttap"
                    src="/Comment1.svg"
                    id={[post.id, post.Directory]}
                    onClick={comment}
                  />
                  <span className="commentcount">
                    {post.Comment_Counter == 0 ? null : post.Comment_Counter}
                  </span>
                </span>
                <span className="redropline">
                  {post.Redropped === true ? (
                    <img
                      src="/Redrop.svg"
                      className="Redrop"
                      onClick={AskUnredrop}
                      id={[post.id, post.Directory, post.Redropped_Conter]}
                    />
                  ) : (
                    <img
                      className="Redrop"
                      src="/PlaneRedrop4.svg"
                      id={[
                        post.id,
                        post.Directory,
                        post.Rank,
                        post.Profile_image,
                        post.text,
                        post.Comment_Counter,
                        post.hour,
                        post.minutes,
                        post.year,
                        post.month,
                        post.date,
                        post.image1,
                        post.image2,
                        post.image3,
                        post.image4,
                        post.video,
                        post.Redropper_id,
                        post.Redropped_Counter,
                      ]}
                      onClick={redrop}
                    />
                  )}
                  <span className="commentcount">
                    {post.Redropped_Counter == 0
                      ? null
                      : post.Redropped_Counter}
                  </span>
                </span>
              </span>
            </div>
          ))}
        </>
      ) : null}
      {unredrop && (
        <>
          <button onClick={ConfirmUnredrop}>Unredrop</button>
          <button onClick={(e) => setUnredrop(false)}>Cancel</button>
        </>
      )}
      <LowControl
        notification={userinfo.Notification}
        messagenotification={userinfo.MessageNotification}
        currenturl={currenturl}
        dropsandcomments={[]}
      />
    </div>
  );
}
export default User;
