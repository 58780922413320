function Videoduration({ videoref }) {
  return (
    <>
      {0 + Math.floor(videoref?.currentTime / 60)}:
      {videoref?.currentTime < 10 && 0}
      {videoref?.currentTime >= 60
        ? Math.floor(videoref?.currentTime % 60)
        : Math.floor(videoref?.currentTime)}
      <span style={{ marginLeft: 5, marginRight: 5 }}>/</span>
      {0 + Math.floor(videoref?.duration / 60)}:{videoref?.duration < 10 && 0}
      {videoref?.duration >= 60
        ? Math.floor(videoref?.duration % 60)
        : Math.floor(videoref?.duration)}
    </>
  );
}

export default Videoduration;
