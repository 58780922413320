import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import "./LowControl.css";

function LowControl({
  notification,
  messagenotification,
  currenturl,
  alldisplay,
  clickedcomment,
  //dropsandcomments,
}) {
  console.log(currenturl);
  console.log(window.location.pathname);
  console.log(window.location.pathname.slice(1, 8));

  //do we even need this prop? cant we just get it from window.hostname or whatever
  const clickedstyle =
    currenturl === "MessageView" || currenturl === "Message"
      ? {
          backgroundImage: `conic-gradient(
    rgb(0, 221, 255),
    rgb(244, 42, 237),
    rgb(115, 242, 170),
    rgba(170, 119, 229, 0.495),
    rgb(0, 221, 255)
  )`,
          borderRadius: "20%",
          padding: "0px 5px",
          //this margin left and right could vary on whether your clicking on the messages, or notification, or home,

          marginLeft: -5,
          marginRight: -5,
        }
      : {};

  //anyway for useeffect to run before the scroll listener?
  //do we even need word break?
  //test in connect
  //apply new styles in usrview,user,connect,notification
  /////////////////////////////////////////////////////
  //className="drops";  simdisplay, clickedcomment,userview, user,notification fix all the height to be the same as alldisplay where its correct
  //do this for usrview,user,connect,notification

  //fix the commentbackid clickedcomment saga and showdrop and all this non sense of things being displayed and not
  //make sure no errors when going back
  //make sure no errors when comment canceling

  //should bigdisplay be imported? or because its so short, its okay the way it is wrtiting it in each compoenet
  //the alldrops display is for simdisplay isdifferent in terms of blockid
  //apply bigdisplay in notification

  //maybe or maybe not sepcifically for alldisplay the text should go heigher margin top if the alldisplay has to be stetched
  //for clicked display ACTUALLY for clickedcoment, becareful making it long since if
  // posted the whole post, it will be so long and the commenter is not going to be able to
  // the top, so maybe you should do the style of 続きを表示 ( similar to alldisplay )
  //for notification fix the text and text slice shiz

  //retweet ayaka post, maya post, post.file no text but image, post.file with text then work on user page replacing the post.text render
  //put and delete the extra post.text render shiz in connect, user, notification
  //and replace it with new one with the slice
  //fix notificaiton posts styling
  //maybe a lil animation of 続きを表示 flashing
  //the lining for the duration time in the connect in the wrong spot now,
  //make sure this doesnt inerfere with fourfiles duration position
  //when theres no text but only files, move up the files with margintop? maybe even make drop post container size smaller? or even the file itsself smaller?
  //apply new styles in usrview,user,connect,notification
  ////////////////////////////////////////

  //actually when users delete +81 then put it automatically in the back

  //maybe color in 脳派 so the actual icon is different color as well
  //maybe the 脳派is a little bit thicker?
  //maybe whole height should be highlightted not just the icon height
  //add lowcontrol in notification as well
  //notification color background based on url
  //make sure though that its in a state, if no files since the messages or nitification
  //could be the files are still loading and it will render that you have no messages and you dont want that
  //if no messages or notification have that on their especailly for new users
  //maybe use picture of eplise dog
  //back button for notification?
  //do we even need currenturl prop? cant we just get it from window.hostname or whatever
  //watch out for currenturl being passed to each click of home, notification, message etc if we going to remove currenturl then fix that as well
  //only if messages dont exceed the screen we need space at the top
  //do we need that much empty space in the message page at the top?
  //render low control in everypage now? ( however its gona be lil tough since the back button needs to be crisp now to where from any page you have to know where you are going back)
  //make sure we change svg of home notification messge icon when clicked on to red? since its hard to see with background
  //when clicking out of commenting in the connect there is an error of scrollinto view
  //perfect the timing of scrollintoview
  //the black barunderneath bar in theにゅん広告and shiz dont render for のあ広告and shiz
  //sometimes it doesnt render for にゅん広告 either
  // when going to connect via the userview and pledge arrow back method seems to not show the bar more prevently(often)
  //make sure in all components that render this lowcontrol coponent plug in alldisplay,
  // clickedcomment, as props if we are using them
  //but dont plug it in if we dont need it like in pledge page and shiz
  //脳はaniation when there are messages and do this for all three icons when there is pending notificiaton
  //with some smartphone, the duration location of 7さん video isnot in right place
  //the back scrolls late, any way to make it faster?
  //when dropping, the drop icon should be highlighted
  //some of th ranks are out of place in the connect simdispalys, not too out of place but slight margin left is needed
  //fix some of the images getting streched, in tempfiles, and dropped images maybe before uploading check the aspect ratio? and display it accordingly
  //when click displaying always scroll to the comment button since the postcan be longandoff screen
  //some of the ranks are not lined up correctly
  //in pledge page when clicking on image, go to the payment page of the particualr plan the photo belongs to, instead of the pledge plan flashing animation

  const [bottom, setBottom] = useState(false);

  /*console.log(window.document.body.offsetHeight);
  console.log(window.innerHeight);
  console.log(window.scrollY);
  */

  const isitbottom = () => {
    //console.log(document.documentElement.scrollHeight);
    console.log(window.document.body.offsetHeight);
    console.log(window.innerHeight);
    console.log(window.scrollY);
    if (window.document.body.offsetHeight > window.innerHeight) {
      let reachedbottom =
        window.innerHeight + Math.ceil(window.scrollY) >=
        window.document.body.offsetHeight;
      if (reachedbottom) {
        console.log("YOOOOOOOOOOO");

        //add the if statement here if its not an auto scroll
        //so you dont have to ask over and over again
        if (window.scrollY) {
          console.log("second");
          setBottom(true);
          //window.removeEventListener("scroll", isitbottom);
          //or the document height must be bigger than the page height since if not it will indicatte that
          //the page hasnt loaded yet
        }
      }
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    console.log("first");
    //this should run before the listener
    //but not a big deal since you can scroll again to stick it even at the bottom
    if (bottom) setBottom(false);
  }, [alldisplay, clickedcomment]);

  useEffect(
    () => {
      //console.log(window.location.pathname.slice(1, 8) !== "Message");
      //console.log(window.location.pathname.slice(1, 13) === "MessageView");

      // if (bottom) setBottom(false);
      if (
        //or use props currenturl === "Message"
        window.location.pathname.slice(1, 8) !== "Message" ||
        window.location.pathname.slice(1, 13) === "MessageView"
      ) {
        window.addEventListener("scroll", isitbottom, {
          passive: true,
        });

        return () => {
          window.removeEventListener("scroll", isitbottom);
        };
      }
    },
    [
      /*alldisplay, clickedcomment*/
    ]
  );

  return (
    <div
      className="Panel"
      style={{
        //bottom && !window.location.pathname.includes("Message") && "sticky",
        position: bottom && "sticky",
      }}
    >
      {console.log(bottom)}
      <img
        style={{ width: 30 }}
        src="/Home2.svg"
        onClick={(e) => navigate(`/Home`, { state: currenturl })}
      />
      <span className="Notifyline">
        <img
          style={{ width: 30 }}
          src="/Notify.svg"
          onClick={(e) => navigate(`/Notification`, { state: currenturl })}
        />

        <span className="notificationnumber">
          {notification ? notification : null}
        </span>
      </span>
      <span className="Messageline">
        <img
          style={{ width: 20, ...clickedstyle }}
          src="/MessageIcon2.svg"
          onClick={(e) => navigate(`/MessageView`, { state: currenturl })}
        />

        <span className="notificationnumber" style={{ marginLeft: 3 }}>
          {messagenotification ? messagenotification : null}
        </span>
      </span>
    </div>
  );
}

export default LowControl;
