import React, { useEffect } from "react";

const SpeedSheet = ({ playbacktime, setspeed }) => {
  const arrowelement = document.createElement("span");
  arrowelement.textContent = "yoooo";
  arrowelement.style.cssText = "color:red";
  console.log("heeeeeeeeeeeeeeeeeee");

  useEffect(() => {
    let element = document.getElementById(playbacktime);
    console.log(element);
    element.prepend(arrowelement);
  }, []);

  const addcheck = (e) => {
    //fix the commentbackid clickedcomment saga and showdrop and all this non sense of things being displayed and not
    //make sure no errors when going back
    //make sure no errors when comment canceling
    //go back to videoplayspeed to finish up the styling and shiz
  };
  return (
    <div
      onClick={(e) => setspeed(e.target.id)}
      style={{
        //  width: "100%",
        height: "60%",
        position: "absolute",
        bottom: 0,
        fontSize: 12,
      }}
    >
      <div id="0.25"> 0.25x </div>
      <div id="0.5">0.5x </div>
      <div id="0.75">0.75x </div>
      <div id="1">1x</div>
      <div id="1.25">1.25x</div>
      <div id="1.5">1.5x </div>
      <div id="1.75">1.75x</div>
      <div id="2"> 2x</div>
      {console.log("RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR")}
    </div>
  );
};
export default SpeedSheet;
