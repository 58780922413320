import React from "react";

const Fullscreenstyles = {
  width: "100%",
  overscrollBehavior: "none",
  touchAction: "none",
};

//export { Fullscreenstyles };

const Fullscreenbackground = {
  position: "fixed",
  width: "100%",
  height: "100%",
  background: "linear-gradient(#17d6e7, rgb(230, 98, 194))",
};
export { Fullscreenstyles, Fullscreenbackground };
