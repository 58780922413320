import React, { useState, useEffect } from "react";
import SpeedSheet from "./SpeedSheet.js";

const VideoPlaySpeed = ({ videoref, originalvideoref }) => {
  const [timersettings, setTimersettings] = useState(false);
  //maybe create element here but assing it in useeffect prolly becase the dom is only ready after useeffect
  //

  console.log(videoref.playbackRate);
  console.log("heeeeeeeeeeeeeeeeeee");

  const setspeed = (time) => {
    videoref.playbackRate = time;
    originalvideoref.playbackRate = time;
    setTimersettings(false);
  };

  const selectspeed = (e) => {
    /* let element = document.getElementById(e.target.id);
    console.log(element);
    const arrowelement = document.createElement("span");
    arrowelement.textContent = "yoooo";
    arrowelement.style.cssText = "color:red";*/
    //  element.prepend(arrowelement);
    //maybe useeffect needed first in THIS COMPOENT since assinging check mark should not cause a rerender
    //get the videoref playback speed
    //actually set the check when pressing the timersettings button
    //dont even need to remove it! just chagne the video speed and close this compoentn
    //make sure when coming in the check mark is where its supposed to be
    //why does sometimes the .remove() is not even needed, but sometimes it is needed?
    //we dont even need remove
    //.remove()
    //the arrow its self should have an id making it easy to remove it
    //why does this compoent get renered 2 times? in the beginning
    //maybe other way is set state with the map and add the text to appropriate div and keep resetting the state?
    //this is assuming the previous added text is not therre in the state
    //if non of them works than use the original logic with saving index of previous element id in a state and remove it
    //when clicking on one of the time, it has to remove the arrowelement, since this will keep adding new ones
    //have the check mark ready? since whoever using this would want to change the speed
    //style the check mark? in the span we created
    //style this to be wide and shiz, if we cant maybe need to put it in the fullscreen video shiz since this wont expand
    //change the videoref playback speed
    //each time you open this compoent make sure the playback speed is different, depending on what it is in the videoref
    //thus the check mark appears in diferent playback speed
    //style it perfectly
    //if this setting the timer each time re renders than use it in custom compoenet
    //close this compoent by sliding ? look at twitter
    //actually when users delete +81 then put it automatically in the back
  };

  return (
    <span>
      {console.log("BRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR")}

      <img
        //style={{ marginTop: 3 }}
        onClick={() => {
          setTimersettings(true);
        }}
        src="/videotimer.svg"
      />
      {
        //have check mark on the videoref
        //scrollable down or up to close or enlarge
        //maybe arrow here to indicate pullable
      }
      {timersettings && (
        <SpeedSheet playbacktime={videoref.playbackRate} setspeed={setspeed} />
        /*<div
          onClick={selectspeed}
          style={{
            //  width: "100%",
            height: "60%",
            position: "absolute",
            bottom: 0,
            fontSize: 12,
          }}
        >
          <div id="1"> 0.25x </div>
          <div id="2">0.5x </div>
          <div id="3">0.75x </div>
          <div id="4">1x</div>
          <div>1.25x</div>
          <div>1.5x </div>
          <div>1.75x</div>
          <div> 2x</div>
          
        </div>*/
      )}
    </span>
  );
};

export default VideoPlaySpeed;
