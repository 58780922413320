import "./FullScreenSide.css";
import useClickCounter from "../../useClickCounter.js";

import React, { useState } from "react";
function FullScreenSide({ videoref, originalvideoref, controlswitch }) {
  const Clicker = useClickCounter();
  const [timechange, setTimechange] = useState([]);

  const fullscreenside = (e) => {
    let clickcount = Clicker();
    console.log(e.target.id);
    if (clickcount === "Once") {
      console.log("YOONE");
      controlswitch();
    }

    if (clickcount === "Twice") {
      console.log("YOTWO");

      let flipdirection =
        e.target.id === "-5" ? [{}, 90] : [{ transform: "scaleX(-1)" }, 0];

      setTimechange([e.clientX, e.clientY, flipdirection[0], flipdirection[1]]);

      videoref.currentTime = Math.floor(videoref?.currentTime - e.target.id);

      originalvideoref.currentTime = originalvideoref.currentTime - e.target.id;

      setTimeout(() => {
        setTimechange([]);
      }, 1000);
    }
  };

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <div
        style={{ background: "black" }}
        className="fullscreencontrol1"
        id={5}
        onClick={fullscreenside}
      ></div>
      <div
        style={{ right: 0, background: "black" }}
        className="fullscreencontrol1"
        id={-5}
        onClick={fullscreenside}
      ></div>
      {timechange.length > 0 && (
        <span
          style={{
            display: "flex",
            zIndex: 2,
            position: "absolute",
            top: timechange[1],
            left: timechange[0] - timechange[3],
            ...timechange[2],
          }}
        >
          <img style={{ width: 45 }} src="fastforward.svg" />
          <img style={{ width: 45 }} src="fastforward.svg" />
        </span>
      )}
    </div>
  );
}

export default FullScreenSide;
