import React, { useState, useEffect, useRef } from "react";
import "./Message.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  collection,
  serverTimestamp,
  addDoc,
  doc,
  setDoc,
  getDoc,
  getDocs,
  orderBy,
  query,
  onSnapshot,
  updateDoc,
  increment,
  Timestamp,
  limit,
  deleteDoc,
} from "firebase/firestore";
import { db, auth, storage } from "./Firebase.js";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  getStorage,
  uploadString,
} from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import LowControl from "./LowControl";
import { Html5Qrcode } from "html5-qrcode";
import useQRreader from "./useQRreader.js";
import FullScreenImage from "./Customhooks/FullScreenImage.js";
import { Fullscreenstyles } from "./Styling/styles.js";

function Message() {
  const { room } = useParams();
  const ref1 = useRef(null);
  const messageloadref = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  //const QRread = useQRreader();
  const QRread = useQRreader;
  const testwidth = document.getElementById("testwidth");

  const [message, setMessage] = useState("");
  const [bmessages, setBmessages] = useState([]);
  const [id, setId] = useState("");
  const [video, setVideo] = useState("");
  const [image, setImage] = useState("");
  const [second, setSecond] = useState(false);
  const [blockon, setBlockon] = useState(false);
  const [loadmessage, setLoadmessage] = useState(false);
  const [userinfo, setUserinfo] = useState("");
  const [viewinfo, setViewinfo] = useState("");
  const [scroll, setScroll] = useState(false);
  const [focus, setFocus] = useState(false);
  const [tempphoto, setTempphoto] = useState("");
  const [tempvideo, setTempvideo] = useState("");
  const [realtempphoto, setRealtempphoto] = useState("");
  const [realtempvideo, setRealtempvideo] = useState("");
  const [messageloaded, setMessageloaded] = useState(false);
  const [messageboxsize, setMessageboxsize] = useState(0);
  const [messagelinecount, setMessagelinecount] = useState(1);
  const [brokerule, setBrokerule] = useState(false);
  const [fullscreentarget, setFullscreentarget] = useState([]);

  let Fullscreenstyleson = fullscreentarget.length ? Fullscreenstyles : {};

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      console.log(location.state);
      if (user) {
        const userBlockRef = doc(db, "users", room, "Blocked", user.uid);
        getDoc(userBlockRef).then((documentSnapshot) => {
          documentSnapshot.data().Blocked_id && setBlockon(true);
        });

        setId(user.uid);
        const userDocumentRef = doc(db, "users", user.uid);
        getDoc(userDocumentRef).then((documentSnapshot) => {
          setUserinfo(documentSnapshot.data());

          const seconduserDocumentRef = doc(db, "users", room);
          getDoc(seconduserDocumentRef).then((documentSnapshot) => {
            setViewinfo(documentSnapshot.data());
          });
        });
      }

      const userCollectionRef = collection(
        db,
        "users",
        user.uid,
        "Message",
        room,
        "Message_details"
      );

      getDocs(query(userCollectionRef, orderBy("Timestamp"))).then(
        (querySnapshot) => {
          setBmessages(
            querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }))
          );

          if (querySnapshot.docs.length === 0) setMessageloaded(true);
        }
      );

      //await
      //Messageload();
      //needs to await this await aint working
      /*setTimeout(() => {
        setScroll(true);
      }, 200);*/
    });
  }, []);

  useEffect(() => {
    if (scroll === true) {
      const element = ref1.current;
      console.log(element);
      element.scrollIntoView();
      setScroll(false);
    }
  }, [scroll]);

  useEffect(() => {
    if (!messageloaded && messageloadref.current) {
      setMessageloaded(true);
    }
  }, [messageloadref.current]);

  /*
  useEffect(() => {
    if (loadmessage === true) {
      onSnapshot(
        collection(db, `users/${id}/Message/${room}/Message_details`),
        (snapshot) => {
          console.log("YO");

          const userCollectionRef = collection(
            db,
            "users",
            id,
            "Message",
            room,
            "Message_details"
          );

          getDocs(query(userCollectionRef, orderBy("Timestamp"))).then(
            (querySnapshot) => {
              setBmessages(
                querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
              );
            }
          );
          setTimeout(() => {
            setScroll(true);
          }, 100);
        }
      );
    }
  }, [loadmessage]);
  */

  const CheckQR = async () => {
    const html5Qrcode = new Html5Qrcode("reader");
    //if its a photo then scan using realtempphoto
    let QRtype;
    await html5Qrcode
      .scanFile(realtempphoto, true)
      .then((decodedtext) => {
        console.log(decodedtext);
        if (decodedtext.includes("pay")) QRtype = "payment";
        else QRtype = "social";
        //return true;
      })
      .catch((err) => {
        console.log(err);
        QRtype = false;
      });
    return QRtype;
  };

  const Confsend = async () => {
    //test qr of non paypay

    ///////////////////////////////////////
    //be able to add images to multiple plan
    //check if click and blur image flashing is working now on mobile
    //when in fullscreen pinch zoom is abled but again no saving images

    //fix the arrow at the top dragging down as you scroll down, you might scroll do position fixed? the top bar and arrow
    //querry limit both message and messageview dms
    //message should be below lowcontrol
    //message icon should be highlighted in lowcontrol when on the messages
    //notification and home should be hightlingted when on it as well
    //move onto notification component slash slash

    const userBlockRef = doc(db, "users", room, "Blocked", id);
    await getDoc(userBlockRef).then((documentSnapshot) => {
      if (documentSnapshot.exists()) return setBlockon(true);
    });
    //documentSnapshot.data().Blocked_id && setBlockon(true);

    let QR;
    if (realtempphoto) QR = await CheckQR();

    console.log(QR);

    let finalfile = "";
    let attached =
      new Date().getTime() -
        bmessages[bmessages.length - 1]?.Timestamp.seconds * 1000 <
      60000
        ? true
        : false;
    new Promise((resolve, reject) => {
      if (tempphoto || tempvideo) {
        const storage = getStorage();
        let storagefile = tempphoto
          ? `images/${realtempphoto.name + new Date().getTime()}`
          : `videos/${realtempvideo.name + new Date().getTime()}`;
        const storageRef = ref(
          storage,

          storagefile
        );

        let uploadfile = tempphoto ? realtempphoto : realtempvideo;

        uploadBytes(storageRef, uploadfile).then((uploadtask) => {
          getDownloadURL(storageRef).then((url) => {
            console.log(url);
            finalfile = url;
            resolve(finalfile);
          });
        });
      } else resolve();
    }).then(() => {
      console.log(finalfile);

      try {
        let messageinfo = {
          text: message,
          Timestamp: serverTimestamp(),
          image1: tempphoto ? finalfile : "",
          video: tempvideo ? finalfile : "",
          from: id,
          to: room,
          attached,
        };

        addDoc(
          collection(db, `users/${id}/Message/${room}/Message_details`),
          messageinfo
        ).then((docRef) => {
          let lit = docRef.id;
          console.log(lit);

          //let Time = new Date();

          let dropobject = {
            id: docRef.id,
            Timestamp: Timestamp.fromDate(new Date()),
            /* year: Time.getFullYear(),
              month: ("0" + (Time.getMonth() + 1)).slice(-2),
              date: ("0" + Time.getDate()).slice(-2),
              hour: Time.getHours(),
              minutes: ("0" + Time.getMinutes()).slice(-2),*/
          };
          setBmessages([...bmessages, { ...messageinfo, ...dropobject }]);

          setTimeout(() => {
            //this set scroll needs to work for images and videos as well
            //make sure loadmessage shiz is completely not being used
            setScroll(true);
          });
        });

        let illegalqr =
          QR === "payment" ||
          (messageinfo.text?.includes("pay") &&
            messageinfo.text?.includes("https"))
            ? {
                image1: "",
                text: "お金はサイトを通してしか受け取れないのでここのPayPay idに送って下さい → eplise",
              }
            : {};
        if (
          QR !== "social" &&
          !messageinfo.text?.includes("@") &&
          !messageinfo.text?.includes("LINE") &&
          !messageinfo.text?.includes("カカオ") &&
          !messageinfo.text?.includes("Twitter") &&
          !messageinfo.text?.includes("ツイッター") &&
          !messageinfo.text?.includes("インスタ") &&
          !messageinfo.text?.includes("twitter") &&
          !brokerule
          // &&!messageinfo.text?.includes("_")
          // !messageinfo.text?.includes("ID") &&
          // !messageinfo.text?.includes("id") &&
        ) {
          addDoc(
            collection(db, `users/${room}/Message/${id}/Message_details`),
            {
              ...messageinfo,
              ...illegalqr,
            }
          );
          setDoc(doc(db, `users/${room}/Message/${id}`), {
            ...messageinfo,
            MessageName: userinfo.User_Name,
            MessageRank: userinfo.Rank,
            MessagePic: userinfo.Profile_image,
            Opened: false,
            ...illegalqr,
          });

          updateDoc(doc(db, `users/${room}/`), {
            MessageNotification: increment(1),
          });
        } else {
          setBrokerule(true);
          //social media reactions
          //do we even need to do any of these except for sending to your own setdoc
          //dont send anything at all?

          /* const postsRef = collection(
            db,
            "users",
            room,
            "Message",
            id,
            "Message_details"
          );
          getDocs(query(postsRef, orderBy("Timestamp", "desc"), limit(2))).then(
            (querySnapshot) => {
              let posts = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }));
              console.log(posts);
              //get the lastest doc
              //maybe limit to 1 instead of 2?
*/
          /*
              const userDocumentRef = doc(
                db,
                "users",
                room,
                "Message",
                id,
                "Message_details",
                posts[1].id
              );
              deleteDoc(userDocumentRef);
*/
          /*setDoc(doc(db, `users/${room}/Message/${id}`), {
                ...posts[0],
                //MessageName: userinfo.User_Name,
                // MessageRank: userinfo.Rank,
                //MessagePic: userinfo.Profile_image,
                Opened: true,
              });*/
          //  }
          // );
        }

        setDoc(doc(db, `users/${id}/Message/${room}`), {
          ...messageinfo,
          MessageRank: viewinfo.Rank,
          MessagePic: viewinfo.Profile_image,
          RecieveName: viewinfo.User_Name,
        });
      } catch (error) {}

      setTimeout(() => {
        setMessage("");
        setTempphoto("");
        setTempvideo("");
        setRealtempphoto("");
        setRealtempvideo("");
        setMessageboxsize(0);
        setMessagelinecount(1);

        //setFocus(false); if you want the textarea to complelty close after sending message
        // setScroll(true);
        //setLoadmessage(true);
      });
    });
    //}
  };

  const MessageaddedtoDOM = () => {
    console.log("Forth");
    if (!messageloaded) setMessageloaded(true);
    else if (bmessages.length > 0) setScroll(true);
  };

  const backurl = () => {
    if (location.state === "UserView") {
      navigate(`/UserView/${room}`);
    } else {
      navigate(`/${location.state[0]}`, { state: location.state[1] });
    }
  };

  const focusscroll = () => {
    setFocus(true);
    setTimeout(() => {
      setScroll(true);
    });
  };

  const fileedit = (e) => {
    console.log(e.target.files[0]);

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      console.log(reader.result);
      if (e.target.files[0].type.includes("image")) {
        setTempphoto(reader.result);
        setRealtempphoto(e.target.files[0]);
        setTempvideo("");
      } else {
        setTempvideo(reader.result);
        setRealtempvideo(e.target.files[0]);
        setTempphoto("");
      }
      e.target.value = "";
      setScroll(true);
    };
  };

  const filedelete = (e) => {
    setTempphoto("");
    setTempvideo("");
  };

  const Messageinput = (e) => {
    console.log(window.innerWidth);

    //is there way for textarea have default set padding so it doesnt differ betwenn different languages
    //rememeber extraheight variable is not needed and you can delete it if you want

    testwidth.textContent = e.target.value;

    console.log(testwidth);
    console.log(testwidth.offsetWidth);
    //console.log(testwidth.clientHeight);
    //console.log(testwidth.textContent.match(/\r|\r\n|\n/));

    /////do not delete this note
    //for English extrawidth is 12
    //for Japanese extrawidth is 6 or i guess its 14 now

    let extrawidthinput = 0; // this is so we used this exact 14 or whatever number this is at the bottom
    let extrawidth = extrawidthinput * messagelinecount;
    console.log(messagelinecount);

    if (
      testwidth.offsetWidth >
      window.innerWidth * messagelinecount - extrawidth
    ) {
      let linenumber = 1;
      let extraextrawidth = extrawidthinput * linenumber + extrawidth;
      let Japaneseheight = Math.floor(
        (messagelinecount /*- 1*/ / 3.5) * linenumber
      );
      console.log(Japaneseheight);
      /*3.6||4*/

      while (
        testwidth.offsetWidth >
        window.innerWidth * (messagelinecount + linenumber) - extraextrawidth
      ) {
        console.log("2 OVEEEEERRRRRR");
        Japaneseheight += Math.floor(
          (messagelinecount + linenumber) /*- 1*/ / 3.5
        );
        /*3.6||4*/
        linenumber += 1;
        extraextrawidth = extrawidthinput * linenumber + extrawidth;
      }

      //do not delete this note/ for Japanese text we add messagelinecount to the messagebox size since the text
      //eventually starts to overlap with the camera ↓

      //line 7 is where it messes up with weird height
      console.log(linenumber);
      let extraheight = 0;
      //if (linenumber /*+ messagelinecount*/ > 6) {
      //  extraheight = /*linenumber + messagelinecount % 7 +*/ linenumber * 1.5;
      // }

      console.log(messagelinecount + linenumber);
      // let Japaneseheight = Math.floor(
      //  (messagelinecount + linenumber) /*- 1*/ / 3.5
      //); /*3.6||4*/

      // let Japaneseheight = Math.floor(
      //  (messagelinecount /*- 1*/ / 3.5) * linenumber
      // ); /*3.6||4*/
      console.log(Japaneseheight);
      setMessageboxsize(
        (prev) => 22 * linenumber + prev + Japaneseheight + extraheight
      );
      setMessagelinecount((prev) => prev + linenumber);

      setTimeout(() => {
        setScroll(true);
      });
      //testwidth.textContent = "";
    } else if (
      messagelinecount > 1 &&
      testwidth.offsetWidth <=
        window.innerWidth * (messagelinecount - 1) -
          (extrawidth - extrawidthinput)
    ) {
      let linenumber = 1;
      let Japaneseheight = Math.floor(
        ((messagelinecount - /*-*/ linenumber) /*- 1*/ / 3.5) * linenumber
      ); /*3.6||4*/
      // let extraextrawidth = 14 * linenumber + extrawidth;
      let extraextrawidth = extrawidth;
      let extraheight = 0;

      while (
        testwidth.offsetWidth <=
        window.innerWidth * (messagelinecount - linenumber - 1) -
          extraextrawidth
      ) {
        if (
          //this should be equal to if we are setting extrawithinput to 0
          window.innerWidth * (messagelinecount - linenumber) -
            extraextrawidth <
          window.innerWidth - extrawidthinput
        ) {
          break;
        }
        console.log("2 OVEEEEERRRRRR");

        linenumber += 1;
        Japaneseheight += Math.floor(
          (messagelinecount - linenumber) /*- 1*/ / 3.5
        );
        // extraextrawidth = 14 * linenumber + extrawidth;
        let decreasenumber = extrawidthinput * linenumber;
        extraextrawidth = extrawidth - decreasenumber;
      }
      console.log(linenumber);
      // if (linenumber > 1) linenumber -= 1;

      console.log(Japaneseheight);
      //(messagelinecount - (linenumber - 1)) /*- 1*/ / 3.5; /*3.6||4*/
      console.log(messageboxsize);
      console.log(22 * (linenumber - 1) + Japaneseheight + extraheight);
      setMessageboxsize(
        (prev) => prev - (22 * linenumber + Japaneseheight + extraheight)
      );
      setMessagelinecount((prev) => prev - linenumber);
    }
    console.log(messagelinecount);

    setMessage(e.target.value);
  };

  const Closefullscreen = (e) => {
    setFullscreentarget([]);
  };

  return (
    <div
      style={{
        ...Fullscreenstyleson,
        //position: fullscreentarget.length > 0 && "fixed",
        // width: fullscreentarget.length > 0 && "100%",
        // overscrollBehavior: fullscreentarget.length > 0 && "none",
        // touchAction: fullscreentarget.length > 0 && "none",

        //the width can be 100% regardless?

        overflowX: "hidden",
      }}
    >
      {<div style={{ display: "none" }} id="reader"></div>}
      {
        <span
          style={{
            display: "hidden",
            fontSize: 16 /*width: "100%"*/,
            whiteSpace: "nowrap",
            fontFamily: "monospace",
          }}
          id="testwidth"
        ></span>
      }

      {fullscreentarget.length && (
        <div
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            height: "100%",
            //overflowX: "clip",
            zIndex: 3,
          }}
        >
          <FullScreenImage
            file={fullscreentarget[0]}
            type={fullscreentarget[1]}
            close={Closefullscreen}
            shape={fullscreentarget[2]}
          />
        </div>
      )}

      <div
        style={{
          width: "100%",
          height: 56,
          display: "block",
          background: "#005280",

          zIndex: 1,
          position: "fixed",
          top: 0,
          //top: 56,
        }}
      ></div>
      <img
        style={{ zIndex: 2, position: "fixed", top: 15, left: 4 }}
        src="/Arrow1.svg"
        onClick={backurl}
      />

      <div
        style={{
          marginTop: bmessages.length <= 2 && 825,
        }}
        className="messageview"
        //onLoad={!messageloaded ? MessageaddedtoDOM : undefined}
      >
        {bmessages.map((bmessage, index, array) => (
          <div
            onLoad={
              //() => setMessageloaded(true)
              MessageaddedtoDOM
            }
            ref={messageloadref}
            key={bmessage.id}
          >
            <div
              style={{
                display: bmessage.from !== id && "flex",
                alignItems: bmessage.from !== id && "flex-end",
              }}
            >
              {bmessage.from !== id && !array[index + 1]?.attached && (
                <img
                  onClick={(e) => navigate(`/UserView/${bmessage.from}`)}
                  src={viewinfo.Profile_image}
                  style={{ width: 30, borderRadius: 20, marginLeft: 4 }}
                />
              )}

              <div
                style={{
                  display:
                    bmessage.from !== id &&
                    bmessage.text &&
                    (bmessage.video || bmessage.image1) &&
                    "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                }}
              >
                {bmessage.image1 && (
                  <div
                    style={{
                      background: "none",
                      marginTop:
                        bmessage.from !== id && bmessage?.attached
                          ? -6
                          : bmessage?.attached
                          ? 0
                          : 40,

                      marginBottom: bmessage.from !== id && -10, //: -1,
                      //its because were using flex on messages that arent ours thats why
                      //we need to adjust it more
                      //this marginbottom is causing your own image and text to have extra space

                      marginLeft:
                        bmessage.from !== id && array[index + 1]?.attached
                          ? 30
                          : bmessage.from !== id
                          ? -6
                          : null,
                    }}
                    className={
                      bmessage.from === id ? "messagestyle" : "messagestyle1"
                    }
                  >
                    <img
                      style={{
                        width: 175,
                        borderRadius: 20,
                        display: "block",
                        border: `1px solid ${
                          bmessage.from === id ? "Chartreuse" : "BlueViolet"
                        }`,
                      }}
                      src={bmessage.image1}
                      onClick={() =>
                        setFullscreentarget([
                          bmessage.image1,
                          "image",
                          "square",
                        ])
                      }
                    />
                  </div>
                )}

                {bmessage.video && (
                  <div
                    style={{
                      background: "none",
                      marginTop:
                        bmessage.from !== id && bmessage?.attached
                          ? -6
                          : bmessage?.attached
                          ? 0
                          : 40,
                      marginBottom: bmessage.from !== id && -8,
                      marginLeft:
                        bmessage.from !== id && array[index + 1]?.attached
                          ? 30
                          : bmessage.from !== id
                          ? -6
                          : null,
                    }}
                    className={
                      bmessage.from === id ? "messagestyle" : "messagestyle1"
                    }
                  >
                    <video
                      controls
                      width="200"
                      height="140"
                      playsInline
                      autoPlay={
                        bmessages[bmessages.length - 1]?.video ===
                          bmessage.video && true
                      }
                      muted
                      loop
                      style={{
                        objectFit: "cover",
                        borderRadius: 15,
                        border: `1px solid ${
                          bmessage.from === id ? "Chartreuse" : "BlueViolet"
                        }`,
                      }}
                    >
                      <source type="video/mp4" src={bmessage.video} />
                    </video>
                  </div>
                )}

                {bmessage.text && (
                  <div
                    style={{
                      marginTop:
                        bmessage.from !== id &&
                        (bmessage.image1 || bmessage.video)
                          ? 6
                          : bmessage.image1 || bmessage.video
                          ? 2
                          : bmessage?.attached /*&&
                            bmessage.from !== id &&
                            (array[index - 1]?.image1 ||
                              array[index - 1]?.video)
                          ? 0 //-18
                          : bmessage?.attached
                          */
                          ? 0
                          : 40,

                      /* marginTop: bmessage?.attached
                        ? 0
                        : bmessage?.image1 || bmessage?.video
                        ? 4
                        : 40,*/

                      marginLeft:
                        bmessage.from !== id && !array[index + 1]?.attached
                          ? 6
                          : bmessage.from !== id && array[index + 1]?.attached
                          ? 39
                          : null,
                      color: "white",
                    }}
                    className={
                      bmessage.from === id ? "messagestyle" : "messagestyle1"
                    }
                  >
                    <div className={bmessage.from === id ? "innermessage" : ""}>
                      {bmessage.text}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                bmessage.from === id && "messagestyle"
                // bmessage.from === id ? "messagestyle" : undefined
              }
              style={{
                fontSize: 10,
                background: "none",
                marginLeft: bmessage.from !== id && 44,
                marginRight: bmessage.from === id && 18,
                marginTop: 2,
                color: bmessage.from !== id ? "BlueViolet" : "chartreuse",
              }}
            >
              {!array[index + 1]?.attached &&
                bmessage.Timestamp?.toDate().getHours() +
                  ":" +
                  ("0" + bmessage.Timestamp?.toDate().getMinutes()).slice(-2)}
            </div>
          </div>
        ))}

        {!blockon ? (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                {(tempphoto || tempvideo) && (
                  <>
                    <div
                      style={{
                        border: "1px solid purple",
                        borderRadius: 10,
                        background: "linear-gradient(#a9baf1, #d9eaf1)",
                        padding: 5,
                        marginTop: 30,
                      }}
                    >
                      {tempphoto && (
                        <img
                          style={{
                            width: 100,
                            borderRadius: 15,
                            height: 110,
                            objectFit: "cover",
                            left: 5,
                          }}
                          src={tempphoto}
                        />
                      )}
                      {tempvideo && (
                        <video
                          controls
                          width="200"
                          height="110"
                          style={{
                            objectFit: "cover",
                            borderRadius: 15,
                          }}
                          playsInline
                          autoPlay
                          muted
                          loop
                        >
                          <source type="video/mp4" src={tempvideo} />
                        </video>
                      )}

                      <img
                        style={{
                          position: "absolute",
                          left: tempphoto ? 85 : 180,
                          width: 15,
                          marginTop: 3,
                        }}
                        src="/Xout.svg"
                        onClick={filedelete}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {/*!messageloaded &&
              setTimeout(() => {
                MessageaddedtoDOM();
              }, 1000)*/}
            {
              //useref when mapped is loaded and useffect that?
            }
            {messageloaded && (
              <>
                <div onLoad={() => setScroll(true)} style={{ display: "flex" }}>
                  <label>
                    <img
                      src="/Photo.svg"
                      style={{
                        width: 30,
                        position: "absolute",
                        marginTop:
                          ((tempphoto || tempvideo) && !focus
                            ? 4
                            : (tempphoto || tempvideo) && focus
                            ? 32
                            : focus
                            ? 71
                            : 44) + messageboxsize,
                        marginLeft: 5,
                      }}
                    />

                    <input onChange={fileedit} className="upload" type="file" />
                  </label>

                  <textarea
                    className="Message"
                    value={message}
                    style={{
                      height: (message || focus) && 55 + messageboxsize,
                      borderTop: (tempphoto || tempvideo) && "none",
                      marginTop: (tempphoto || tempvideo) && 0,
                    }}
                    onFocus={focusscroll}
                    onBlur={(e) => !message && setFocus(false)}
                    //onChange={(e) => setMessage(e.target.value)}
                    onChange={Messageinput}
                    ref={ref1}
                    placeholder="メッセージを送りましょう..."
                  ></textarea>
                  {(message || tempphoto || tempvideo) && (
                    <img
                      style={{
                        marginTop:
                          ((tempphoto || tempvideo) && !focus
                            ? 4
                            : (tempphoto || tempvideo) && focus
                            ? 30
                            : focus
                            ? 71
                            : 44) + messageboxsize,
                        marginLeft: 5,
                      }}
                      className="messagedrop"
                      src="/messagedrop.svg"
                      onClick={Confsend}
                    />
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <div ref={ref1}> あなたはブロックされています</div>
        )}
        {<LowControl currenturl={"Message"} />}
      </div>
    </div>
  );
}

export default Message;
