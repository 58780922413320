import React, { useState, useEffect } from "react";
import "./PlayPause.css";
function PlayPause({ videoref, originalvideoref, playvideo, pausevideo }) {
  const [render, setRender] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {!videoref?.paused /*&& !paused*/ ? (
        <img
          style={{
            width: 15,
          }}
          className="playpausestyle"
          src="/ConnectPause.svg"
          onClick={() => {
            videoref?.pause();
            originalvideoref.pause();
            // pausevideo();
            setRender(!render);
          }}
        />
      ) : (
        <img
          className="playpausestyle"
          style={{ width: 50 }}
          src="/ConnectPlay.svg"
          onClick={() => {
            // playvideo();
            videoref?.play();
            originalvideoref.play();
            setRender(!render);
          }}
        />
      )}
    </div>
  );
}
export default PlayPause;
