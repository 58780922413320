import React, { useState, useEffect, useRef } from "react";
import "./FullScreenVideo.css";
import { Fullscreenbackground } from "../Styling/styles";
import Videoduration from "./Videoduration.js";
import PlayPause from "./PlayPause.js";
//import useClickCounter from "../useClickCounter.js";
import Mute from "./FullScreenProps/Mute.js";
import VideoPlaySpeed from "./FullScreenProps/VideoPlaySpeed.js";
import FullScreenSide from "./FullScreenProps/FullScreenSide.js";

function FullScreenVideo({ file, shape, originalvideoref, close, isit }) {
  const videoref = useRef(null);
  const [optionon, setOptionon] = useState(true);
  const [controlon, setControlon] = useState(null);
  const [showtime, setShowtime] = useState(false);
  const [videotime, setVideotime] = useState(0);
  const [timechange, setTimechange] = useState([]);

  // const Clicker = useClickCounter();

  console.log(file);
  console.log(shape);
  console.log(originalvideoref);

  let itemstyle =
    shape === "Long"
      ? [{}, "/Arrow1.svg", 37, 630, {}, 627]
      : [
          Fullscreenbackground,
          "/Xout.svg",
          30,
          55,
          { marginTop: "70%", height: 270 },
          52,
        ];

  //fullscreenimage styles should be imported like with fullscreebackground
  //see if no errors
  //change the xout to like a red arrow make the red arrow in inkcape dont wanna mix arrows with xout have consistency
  /////////////////////////////////////////////
  //so maybe simply convert an object to a string?
  //use ref is an object? how do we get the element out?
  //now that we know html can be used as props, any way to extract the htmldivelement from the prop
  //faster way for video to load like twitter? any way to dislay ref?
  //when coming in the video current time has to be the same before the video loads
  //whats the benefit of react video player? look up
  //when turned sideways, how does the onclick area change?
  //onclick long still works after the change?
  //what is forward ref?
  //what is usecallback ref?
  //what is air play?
  //perfect the sizing of props of mute and ear buds and shiz
  //maybe we dont even need new videoref, just use the one coming in with ontimeupdate and slider
  //decorate with maybe slash in beween the props of mute and earbuds shiz
  ////////////////////////////////////////////////////////
  //good spacing in between the props of mute and ear buds and shiz
  //video playing speed,0.5,2,3
  //also feature to make video play on the bottom?
  //and be able to move this mini video around
  //would this messup code? if so fix it
  //go in and out of big screen small screen when playing at the bottom
  //when in small screen but click on different video, be able to full screen smoothly image and video
  //plug in all control shiz on screen and put it in right locations
  //line up earbud correctly for silent and regular
  //make sure videoslider is not off screen after the line up
  //and line them up correctly for Wide, and Long
  //adjust the black background based on long and wide, however dont let it
  //cover the props like duration and slideers and mute and shiz
  //consider it getting smartphone flipped change video size and style for long and wide
  //make sure all props is lined up when smartphone flipped for long and wide
  //make sure all black background is line up when smartphone flipped
  //make sure small vids are closed when video is deleted or you are out of tempfiles uploadeer mode in the droppy since videos get deleted when you go out of it
  //lil bit of animation for the arrow of fast forwarding
  /////////////////////////////////////////////////////////////////////////////////
  //flip the height and width of screen of videos and change the icon layouts and black marks
  //get rid of black marks?
  //REALTIME FLIP we need to simulate the screen style change using orientashion, or resize event listenr
  //we might need a state to keep track if video was first moved down or up

  //swipe event regular wide : up down left right
  //swipe event regular long : up down left right
  //swipe event MOVIE wide : up down left right
  //swipe event MOVIE LONG : up down left right

  //each action needs to disable the others,down,up,sides
  //if moved down cant do the move up function
  //when swipe down move the videos around
  //decrease the opacity
  //the video should 合体 with originaltemp video as an animation when exiting out
  //ontouchend action, this will either reset everthing back, or close this compoenent

  //swipe up event ( only enable if tempfiles dont exist which means its connect or user or user view page)
  //swipe left and right should get to the next images or videos in the post
  //if no videos to get to, then simply go back to original video

  //for images
  //swipe event regular wide : up down left right
  //swipe event regular long : up down left right
  //swipe event MOVIE wide : up down left right
  //swipe event MOVIE LONG : up down left right
  //apply this to images full screen?

  //come in and exit with swipe
  //swipe event of long video is going to be different than swipin out of images
  //swiped exited video should have a snapping effect to the original video
  //if you turn it does it become fullscreen, check this by if width is longer than height which signals the phone has been flipped,
  //or maybe there is an specific on event
  //if we are editing wide full screen added to the fullscreenbackground shiz like {...Fullscreenbackground, width:etc.}
  //imported swipe events should work on images as well
  //when fullscreening original video should be paused? and when attached or exited it should start playing current time transfered
  //fix some of the images getting streched, in tempfiles, and dropped images maybe before uploading check the aspect ratio? and display it accordingly
  //postion of videotime slider
  //design videotime slider lil bit
  //the location of all contents in controlon needs to be differemt when wide, long, or wide flipped, or long fipped
  //when we are sliding the videotimechange, the ontimeupdate still moves, so it fights with us, so maybe pause the ontimeupdate when we are sliding the videotimechnage?
  //the slider and duration movement is a lil out of sync
  //when exiting out of video the currenttime of fullscreen has to match the tempfile video
  //when coming in to userview page once, the video works, but when refreshing the page, the video doesnt play anymore
  //same thing with user page and connect? or even notification?
  //swipe left and right should take you to the next video, or image
  //back to four files slash slash

  //after refactoring connect drops files user files, userview files apply this
  //now we can do swipe up and change videos
  //swipe event regular wide : up down left right
  //swipe event regular long : up down left right
  //swipe event MOVIE wide : up down left right
  //swipe event MOVIE LONG : up down left right

  //apply to images after refactoring connect drops files user files, userview files apply this
  //swipe event regular wide : up down left right
  //swipe event regular long : up down left right
  //swipe event MOVIE wide : up down left right
  //swipe event MOVIE LONG : up down left right

  //postioin of video slider and other props is not seen on mobile
  //may have to use percentage instead of pixels,
  //need to try it with camera and planet hidden when we are in full screen
  //try it with wide videos as well
  //then flip it as well for both cases

  const controlswitch = () => {
    if (controlon === null) setControlon(true);
    else {
      setControlon(!controlon);
      setOptionon(!optionon);
    }
  };

  const videotimeset = (time) => {
    //ACTUALLY WE DONT EVEN NEED THIS CALLBACK function, we can directly effect the ref in the child component
    videoref.current.currentTime = time;
  };

  const playvideo = (time) => {
    videoref?.current.play();
    originalvideoref.play();
  };

  const pausevideo = (time) => {
    videoref?.current.pause();
    originalvideoref.pause();
  };

  const Videotimechange = (e) => {
    //let timeadjust = parseInt(e.target.value) - videotime;
    //let timeadjust = parseInt(e.target.value) - videoref.current.currentTime;
    //setVideotime(parseInt(e.target.value));

    /*videoref.current.currentTime =
      Math.floor(videoref.current.currentTime) + timeadjust;*/

    originalvideoref.currentTime = parseInt(e.target.value);
    videoref.current.currentTime = parseInt(e.target.value);
  };

  const fullscreenside = (e) => {
    let clickcount; // = Clicker();
    console.log(e.target.id);
    if (clickcount === "Once") {
      console.log("YOONE");
      controlswitch();
    }

    if (clickcount === "Twice") {
      console.log("YOTWO");

      let flipdirection =
        e.target.id === "-5" ? [{}, 90] : [{ transform: "scaleX(-1)" }, 0];

      setTimechange([e.clientX, e.clientY, flipdirection[0], flipdirection[1]]);

      videoref.current.currentTime = Math.floor(
        videoref?.current?.currentTime - e.target.id
      );

      originalvideoref.currentTime = originalvideoref.currentTime - e.target.id;

      setTimeout(() => {
        setTimechange([]);
      }, 1000);
    }
  };

  return (
    <div style={{ ...itemstyle[0] }}>
      {console.log("RENDDDDDDDDDDDDDDDDDDDDD")}

      {/*isit*/}
      {optionon && (
        <img
          style={{
            marginLeft: 5,
            position: "absolute",
            top: 5,
            width: itemstyle[2],
            zIndex: 1,
          }}
          src={itemstyle[1]}
          onClick={close}
        />
      )}

      <video
        ref={videoref}
        style={{
          ...itemstyle[4],

          //...itemstyle[0],
          //just devide by 100?
          // opacity: 1 - Math.abs(moveto) / 100,
        }}
        playsInline
        autoPlay={!originalvideoref.paused ? true : false}
        loop
        //className={shape}

        onTimeUpdate={() => {
          setShowtime(!showtime);
          //setVideotime(Math.floor(videoref.current.currentTime) + 1);
        }}
        //dont show the time until nan is gone go fix it should we do this in the parent or the child compoenet
        // onLoadedData={() => setShowtime(true)}// we might not even need this, just get time ontimeupdate working and that would be good enough
        /*onLoadedData={() =>
          (videoref.current.currentTime = originalvideoref.currentTime + 1)
        }
       
          
        onLoadedMetadataCapture={() =>
          (videoref.current.currentTime = originalvideoref.currentTime + 1)
        }*/
        onClick={controlswitch}
        onLoadedMetadata={
          () =>
            (videoref.current.currentTime =
              originalvideoref.currentTime) /*+ 1this plus one prolly not needed */
        }
      >
        <source src={file} type="video/mp4" />
      </video>

      {/*
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{ background: "black" }}
            className="fullscreencontrol1"
            id={5}
            onClick={fullscreenside}
          ></div>
          <div
            style={{ right: 0, background: "black" }}
            className="fullscreencontrol1"
            id={-5}
            onClick={fullscreenside}
          ></div>
        </div>
        */}

      {/*timechange.length > 0 && (
        <span
          style={{
            display: "flex",
            zIndex: 2,
            position: "absolute",
            top: timechange[1],
            left: timechange[0] - timechange[3],
            ...timechange[2],
          }}
        >
          <img style={{ width: 45 }} src="fastforward.svg" />
          <img style={{ width: 45 }} src="fastforward.svg" />
        </span>
      )*/}
      <FullScreenSide
        videoref={videoref.current}
        originalvideoref={originalvideoref}
        controlswitch={controlswitch}
      />

      {controlon && (
        <>
          <PlayPause
            videoref={videoref.current}
            originalvideoref={originalvideoref}
            //playvideo={playvideo}
            // pausevideo={pausevideo}
          />

          <span
            style={{
              display: "flex",
              //bottom: -550,
              bottom: itemstyle[5],
              width: "100%",
              zIndex: 1,
              alignItems: "center",
              justifyContent: "space-between",
            }}
            className="videoduration1"
          >
            <span>{<Videoduration videoref={videoref?.current} />}</span>
            <span style={{ marginRight: 15 }}>
              {<Mute videoref={videoref?.current} />}
              {
                <VideoPlaySpeed
                  videoref={videoref?.current}
                  originalvideoref={originalvideoref}
                />
              }
            </span>
          </span>

          {
            <input
              type="range"
              style={{
                width: "100%",
                position: "relative",
                bottom: itemstyle[3],

                zIndex: 1,
              }}
              min="0"
              max={Math.floor(videoref?.current?.duration)}
              // value={videotime}
              // defaultValue={originalvideoref.currentTime}
              value={videoref?.current?.currentTime}
              onChange={Videotimechange}
            ></input>
          }
          {/*
          <VideoTimeSlider
            videoref={videoref.current}
            videotimeset={videotimeset}
          />*/}
        </>
      )}
      {/*originalvideoref*/}
    </div>
  );
}
export default FullScreenVideo;
