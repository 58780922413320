import React, { useState } from "react";

const Mute = ({ videoref }) => {
  const [render, setRender] = useState(true);
  return (
    <span>
      <img
        style={{ marginTop: 3 }}
        onClick={() => {
          videoref.muted = !videoref.muted;
          setRender(!render);
        }}
        src={videoref.muted ? "/earbudsilent1.svg" : "earbud1.svg"}
      />
    </span>
  );
};

export default Mute;
